import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'theme-ui';
import { Auth0Provider } from '@auth0/auth0-react';
import { theme } from './theme';
import App from './App';
import store from './state/store';
import './assets/_fonts.css';
import { DoppioProvider, snap } from '@odekoteam/doppio';
import 'form-request-submit-polyfill';
import ErrorBoundary from './components/ErrorBoundary';
import { initErrorReporter } from '@/services/ErrorReporter';

initErrorReporter();

const domain = window.env.AUTH0_DOMAIN;
const clientId = window.env.AUTH0_CLIENT_ID;
const audience = window.env.AUTH0_AUDIENCE;

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}
    audience={audience}
    scope="read:current_user update:current_user_metadata"
  >
    <React.StrictMode>
      <Provider store={store}>
        <DoppioProvider
          applicationName="item-management-web"
          _theme={snap}
          _logLevel="silent"
        >
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </ThemeProvider>
        </DoppioProvider>
      </Provider>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root'),
);
