import {
  ComponentPropsWithRef,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button } from 'theme-ui';

enum CopyState {
  Copied,
  Error,
  Ready,
}

type Props = ComponentPropsWithRef<'div'> & {
  copiedTitle?: string;
  errorTitle?: string;
  title?: string;
  value?: boolean | number | string | null;
};

export default function CopyableText({
  children,
  copiedTitle = 'Copied!',
  errorTitle = 'Could not copy',
  title = 'Click to copy',
  value = null,
  ...props
}: Props) {
  const [copyState, setCopyState] = useState<CopyState>(CopyState.Ready);
  const [timer, setTimer] = useState<number | null>(null);
  const contentEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      if (timer) window.clearTimeout(timer);
    };
  }, []);

  function copyContent(e: MouseEvent) {
    e.preventDefault();

    let state = CopyState.Error;
    let content = value ?? contentEl.current?.innerText ?? null;
    if (content !== null) content = String(content);
    if (content) {
      try {
        if (navigator.clipboard) navigator.clipboard.writeText(content);
        else document.execCommand('copy', false, content);
        state = CopyState.Copied;
      } catch (e) {
        /* ignore */
      }
    }

    setCopyState(state);
    if (timer) window.clearTimeout(timer);
    setTimer(window.setTimeout(() => setCopyState(CopyState.Ready), 3000));
  }

  let [icon, currentTitle] = ['📋', title];
  if (copyState === CopyState.Error) [icon, currentTitle] = ['❌', errorTitle];
  else if (copyState === CopyState.Copied)
    [icon, currentTitle] = ['✅', copiedTitle];

  return (
    <div sx={{ display: 'flex', alignItems: 'center' }} {...props}>
      <div ref={contentEl} sx={{ flex: 'auto' }}>
        {children}
      </div>
      <Button
        onClick={copyContent}
        sx={{ flex: 'none', fontSize: 'inherit', padding: 1 }}
        title={currentTitle}
        variant="transparent"
      >
        {icon}
      </Button>
    </div>
  );
}
