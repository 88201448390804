import { Currency, Money, MoneyInput } from '@/graphql/types/generated';
import Decimal from 'decimal.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import { isEmpty } from 'lodash';

type PricingDefaults = {
  amount?: Decimal.Value;
  currency?: Currency;
};

export const formatMoney = (
  val: Money | null | undefined,
  maximumFractionDigits = 4,
): string => {
  const money = priceOutput(val, { amount: NaN });
  if (!isValidMoney(money)) return '';

  return money.amount!.toNumber().toLocaleString(undefined, {
    style: 'currency',
    currency: val!.currency,
    maximumFractionDigits,
  });
};

export const isValidMoney = (val: Money | null | undefined): boolean => {
  if (!val) return false;
  return (
    val.cents !== null &&
    Boolean(val.currency || val.currencyISO) &&
    (val.amount?.isFinite() || isFinite(val.cents))
  );
};

export const priceDecimal = (
  price: Maybe<Decimal.Value>,
  { amount: amountDefault = 0, currency = Currency.Usd }: PricingDefaults = {},
): Money => {
  const amount = new Decimal(isEmpty(price) ? amountDefault : price!);
  return {
    amount,
    cents: amount.mul(100).trunc().toNumber(),
    currency,
    currencyISO: currency,
  };
};

export const priceOutput = (
  price: Maybe<Money>,
  {
    amount: amountDefault,
    currency: currencyDefault = Currency.Usd,
  }: PricingDefaults = {},
): Money => {
  const amount = moneyToDecimal(price, amountDefault);
  const currency =
    price?.currency ?? (price?.currencyISO as Currency) ?? currencyDefault;
  return {
    amount,
    cents: amount.mul(100).trunc().toNumber(),
    currency,
    currencyISO: currency,
  };
};

export const priceInput = (
  price: Maybe<Money>,
  {
    amount: amountDefault,
    currency: currencyDefault = Currency.Usd,
  }: PricingDefaults = {},
): MoneyInput => {
  const amount = moneyToDecimal(price, amountDefault);
  return {
    cents: amount.mul(100).trunc().toNumber(),
    currencyISO:
      price?.currency ?? (price?.currencyISO as Currency) ?? currencyDefault,
  };
};

function moneyToDecimal(
  price: Maybe<MoneyInput | Money>,
  defaultValue: Decimal.Value = 0,
): Decimal {
  if (!price) return new Decimal(defaultValue);
  else if (typeof price.amount === 'string') return new Decimal(price.amount);
  else if (price.amount?.isFinite()) return new Decimal(price.amount);
  else if (price.cents) return new Decimal(price.cents).div(100);
  else return new Decimal(defaultValue);
}
