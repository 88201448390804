import { ThemeUICSSObject } from 'theme-ui';

export const headerTitle: ThemeUICSSObject = {
  py: 3,
};
export const sidebarContainerStyles: ThemeUICSSObject = {
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100vh',
  position: 'sticky',
  top: 0,
  left: 0,
};
export const navigationStyles: ThemeUICSSObject = {
  pt: 1,
  px: 6,
};
export const linkStyles: ThemeUICSSObject = {
  display: 'block',
  width: '100%',
  paddingY: 2,
  textDecoration: 'none',
  color: 'neutralDark',
  '&:hover': {
    backgroundColor: 'secondaryHighlight',
  },
};
export const imgStyles: ThemeUICSSObject = {
  verticalAlign: 'middle',
  paddingLeft: 3,
};
export const userImgStyles: ThemeUICSSObject = {
  verticalAlign: 'middle',
  marginLeft: 3,
  height: '24px',
  position: 'relative',
  left: 0,
  top: 0,
};
export const linkTextStyles: ThemeUICSSObject = {
  fontSize: 1,
  fontWeight: 'regular',
  paddingLeft: 3,
  verticalAlign: 'middle',
  color: 'secondaryDefault',
};
export const homeStyles: ThemeUICSSObject = {
  pt: 1,
  px: 6,
  pb: 6,
};
