import { useLocationItemsByItemIdQuery } from '@/graphql/types/generated';
import { spinnerStyle } from '@/components/BaseBrandedItemIndex/ItemTableStyles';
import { Divider, Spinner, Text } from 'theme-ui';
import LocationItem from '@/components/LocationItemManager/LocationItem';
import React from 'react';

type Props = {
  itemId?: string;
  onRemove?: (locationId: string) => any;
  isReadonly?: boolean;
  showHeader?: boolean;
};

const LocationItemsByItemId = ({
  itemId,
  onRemove,
  isReadonly = false,
  showHeader = true,
}: Props) => {
  if (!itemId) {
    return null;
  }
  const list: string[] = [];

  const [locationItemsState, query] = useLocationItemsByItemIdQuery({
    variables: {
      locationItemsByItemId: itemId,
    },
    requestPolicy: 'network-only',
  });
  const { data } = locationItemsState;

  if (locationItemsState.fetching) {
    return <Spinner sx={spinnerStyle} />;
  }

  data?.locationItemsByItemId?.forEach((v) => {
    if (v) {
      list.push(v.locationId);
    }
  });

  if (locationItemsState.fetching) {
    return <Spinner sx={spinnerStyle} />;
  }

  const removeLocationItemHandler = isReadonly
    ? undefined
    : (locationId: string) => {
        query({
          variables: {
            locationItemsByItemId: itemId,
          },
          requestPolicy: 'network-only',
        });
        if (onRemove) {
          onRemove(locationId);
        }
      };

  const headerView = showHeader ? (
    <>
      <Text sx={{ variant: 'text.labelLg' }}>
        Location Item&apos;s ({list.length})
      </Text>
      {list.length ? <Divider variant="forms.itemUpsert.divider" /> : null}
    </>
  ) : null;

  return (
    <>
      {headerView}
      {list.map((locationId, index) => {
        return (
          <LocationItem
            key={`${index}-${locationId}`}
            locationId={locationId}
            itemId={itemId}
            onRemove={removeLocationItemHandler}
          />
        );
      })}
    </>
  );
};

export default LocationItemsByItemId;
