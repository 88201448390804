import { FC } from 'react';
import { Box, Flex, ThemeUICSSObject } from 'theme-ui';
import Sidebar from '../components/Sidebar';
import { TOP_OFFSET } from '@/components/DevelopmentEnvironmentBanner';

const TwoColLayout: FC<{}> = ({ children }) => {
  const sidebarStyles: ThemeUICSSObject = {
    flex: '0 0 250px',
    borderRight: '1px solid',
    borderRightColor: 'secondaryHighlight',
  };

  const mainContainerStyles = {
    flex: '1',
    maxWidth: '1024px',
    margin: '0 auto',
    padding: '20px 40px',
  };

  return (
    <Flex sx={{ marginTop: `${TOP_OFFSET}px` }}>
      <Box sx={sidebarStyles}>
        <Sidebar />
      </Box>
      <Box sx={mainContainerStyles} as="main">
        {children}
      </Box>
    </Flex>
  );
};

export default TwoColLayout;
