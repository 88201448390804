import { ThemeUICSSObject } from 'theme-ui';

export const dropdownContainerStyles: ThemeUICSSObject = {
  width: '281px',
  mx: -4,
  my: -2,
};
export const linkStyles: ThemeUICSSObject = {
  py: 2,
};
export const optionContainerStyles: ThemeUICSSObject = {
  px: 4,
  py: 3,
  '&:hover': {
    bg: 'secondaryHighlight',
  },
};
export const titleStyles: ThemeUICSSObject = {
  pt: 0,
  pb: 1,
  px: 0,
  fontWeight: 'semibold',
};
export const descriptionStyles: ThemeUICSSObject = {
  fontSize: 1,
  color: 'secondaryText',
};
