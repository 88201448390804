import { filterActions } from '@/state/Filters';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { useGetBaseBrandedItemCategoriesQuery } from '@/graphql/types/generated';
import { NativeSelect, NativeSelectProps } from '@odekoteam/doppio';

type SubcategoryFilterSelectProps = {
  disabled?: boolean;
};

const SubcategoryFilterSelect = ({
  disabled,
}: SubcategoryFilterSelectProps) => {
  const { selectedSubcategoryId, selectedCategoryId } = useAppSelector(
    (state) => state.filters,
  );

  const dispatch = useAppDispatch();

  const [categoryResponse] = useGetBaseBrandedItemCategoriesQuery();

  const categoryList = categoryResponse.data?.categories || [];

  const subcategoryList =
    categoryList.filter((category) => {
      if (selectedCategoryId === undefined) {
        return category.parentCategory !== null;
      }
      return category.parentCategory?.id === selectedCategoryId;
    }) || [];

  const items: NativeSelectProps['items'] = [
    { label: 'All Subcategories', value: '' },
    ...subcategoryList.map((o) => ({ value: o.id, label: o.name })),
  ];

  const handleChange: NativeSelectProps['onValueChange'] = (value) => {
    if (value === '') {
      dispatch(filterActions.resetSubcategory());
    } else {
      dispatch(filterActions.setSubcategory(value as string));
      if (selectedCategoryId === undefined) {
        const category = categoryList.find((c) => c.id === value);
        const parentCategoryId = category?.parentCategory?.id;
        if (!parentCategoryId) {
          throw new Error(
            `ParentCategoryId is undefined for SubcategoryId ${value}`,
          );
        }
        dispatch(filterActions.setCategory(parentCategoryId!));
      }
    }
  };

  return (
    <NativeSelect
      value={selectedSubcategoryId ?? ''}
      isDisabled={disabled}
      items={items}
      onValueChange={handleChange}
    />
  );
};

export default SubcategoryFilterSelect;
