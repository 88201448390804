import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CombinedError = {
  graphQLErrors: any[];
  message: string;
  name: string;
  networkError?: any;
};

export type Error = {
  code?: string;
  message: string;
};

type ErrorState = {
  errors: Error[] | null;
};

const initialState: ErrorState = {
  errors: null,
};

const ErrorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    resetErrors: (state) => {
      state.errors = [];
    },
    setGraphqlErrors: (state, { payload }: PayloadAction<CombinedError>) => {
      ErrorActions.resetErrors();

      payload.graphQLErrors?.forEach((error) => {
        state.errors?.push({
          code: error.extensions.code,
          message: payload.message,
        });
      });

      if (payload.networkError) {
        state.errors?.push({
          message: payload.message,
        });
      }
    },
  },
});
export const ErrorActions = ErrorSlice.actions;

export default ErrorSlice.reducer;
