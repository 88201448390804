import ReactTooltip from 'react-tooltip';
import { Image } from 'theme-ui';
import QuestionMark from '@/assets/images/QuestionMark.svg';

type TooltipProps = {
  description: string;
  name: string;
};

export default ({ description, name }: TooltipProps) => {
  return (
    <>
      <Image
        alt="tooltip"
        src={QuestionMark}
        data-for={`${name}-tooltipText`}
        data-tip={`${description}`}
        sx={{ marginBottom: '8px' }}
      />
      {/* For additional functionality check here: https://github.com/wwayne/react-tooltip */}
      <ReactTooltip id={`${name}-tooltipText`} effect="solid" />
    </>
  );
};
