import React, { useEffect } from 'react';
import FilterArea from '../components/BaseBrandedItemIndex/FilterArea';
import TwoColLayout from '../layouts/two-col';
import ItemTableContainer from '@/components/BaseBrandedItemIndex/ItemTableContainer';
import ErrorBanner from '@/components/ErrorBanner';
import { useAppDispatch } from '@/hooks/store';
import { ErrorActions } from '@/state/errors';
import { filterActions } from '@/state/Filters';
import ImsPageHeader from '@/components/common/ImsPageHeader';
import { Button } from '@odekoteam/doppio';
import { Link } from 'react-router-dom';

const BaseBrandedItemIndex = () => {
  document.title = 'Based Branded Items';
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(ErrorActions.resetErrors());
    dispatch(filterActions.resetState());
  }, []);

  return (
    <TwoColLayout>
      <ImsPageHeader headerText="Base Branded Items List">
        <Link
          to="/base_branded_items/lead_times"
          sx={{ marginLeft: 'auto', marginRight: '6' }}
        >
          <Button variant="tertiary">Edit lead times</Button>
        </Link>
      </ImsPageHeader>
      <ErrorBanner />
      <FilterArea />
      <ItemTableContainer />
    </TwoColLayout>
  );
};

export default BaseBrandedItemIndex;
