import React, { useState } from 'react';
import TwoColLayout from '@/layouts/two-col';
import {
  ThemeUICSSObject,
  Box,
  Text,
  Spinner,
  Divider,
  Paragraph,
} from 'theme-ui';
import {
  CatalogStatus,
  useGetItemNameByUuidQuery,
  useUpsertLocationItemMutation,
} from '@/graphql/types/generated';
import LocationAutocomplete from '@/components/LocationAutocomplete';
import CatalogItemAutocomplete from '@/components/CatalogItemAutocomplete';
import { AutocompleteOption } from '@doppio/elements/build/Autocomplete/AutocompleteList';
import LocationItemsByItemId from '@/components/LocationItemManager/LocationItemsByItemId';
import LocationItem from '@/components/LocationItemManager/LocationItem';
import ImsPageHeader from '@/components/common/ImsPageHeader';
import { Link, useParams } from 'react-router-dom';
import { spinnerStyle } from '@/components/BaseBrandedItemIndex/ItemTableStyles';
import { locationItemsDescription } from '@/components/UpsertItem/types/UpsertItemForm';
import { getLocationAcPlaceHolderText } from '@/components/LocationItemManager/utils';

export const locationNameStyle: ThemeUICSSObject = {
  color: 'secondaryDefault',
  variant: 'text.labelMd',
};

export const LocationItemManagerUrl = '/location-item-manager';

export const getLocationItemUrl = (itemId?: string) => {
  const { pathname } = window.location;
  if (
    itemId &&
    !pathname.startsWith('/base_branded_items') &&
    !pathname.startsWith(LocationItemManagerUrl)
  ) {
    return `${LocationItemManagerUrl}/${itemId}`;
  }
  return `${LocationItemManagerUrl}`;
};

export type UrlProps = {
  id: string;
};

const LocationItemManager = () => {
  let { id } = useParams<UrlProps>();
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(id);
  const [newLocations, setNewLocations] = useState<AutocompleteOption[]>([]);
  const [, upsertLocationItemMutation] = useUpsertLocationItemMutation();
  let catalogStatus: CatalogStatus | undefined;

  const documentTitle = 'IMS - Location Item Manager';
  document.title = documentTitle;

  let initialTextValue = '';
  const [state] = useGetItemNameByUuidQuery({
    variables: {
      itemId: selectedItemId || '',
    },
    pause: !selectedItemId,
  });
  if (selectedItemId) {
    if (state.fetching) {
      return <Spinner sx={spinnerStyle} />;
    }
    if (!state.data?.viewer?.availableItemById) {
      window.location.href = LocationItemManagerUrl;
    } else {
      const item = state.data?.viewer?.availableItemById;
      initialTextValue = item?.name;
      catalogStatus = item?.catalogStatus;
      if (id) {
        document.title = `${documentTitle} - ${item?.name}`;
      }
    }
  }

  const isLocationAcDisabled = catalogStatus === 'IN_CATALOG';
  const locationAcPlaceholderText = getLocationAcPlaceHolderText(catalogStatus);
  const onItemSelect = (itemId?: string) => {
    setSelectedItemId(itemId);
    setNewLocations([]);
  };

  const onLocationSelect = (item: AutocompleteOption) => {
    if (!selectedItemId) {
      return;
    }
    const locationUuid = item.value as string;
    upsertLocationItemMutation({
      input: {
        locationId: locationUuid,
        itemId: selectedItemId,
      },
    }).then(() => {
      if (!newLocations.find((v) => (v.value as string) === locationUuid)) {
        setNewLocations((newLocations) => [item, ...newLocations]);
      }
    });
  };
  const removeResetLocationItemHandler = (
    locationId: string,
    forceReload: boolean | undefined = false,
  ) => {
    setNewLocations(newLocations.filter((v) => v.value !== locationId));
    if (forceReload) {
      const oldValue = selectedItemId;
      setSelectedItemId(undefined);
      setSelectedItemId(oldValue);
    }
  };

  return (
    <TwoColLayout>
      <ImsPageHeader headerText="Location Item Manager" />
      <Paragraph variant="forms.itemUpsert.paragraph">
        {locationItemsDescription}
      </Paragraph>

      <Divider variant="forms.itemUpsert.divider" />

      <div
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginY: 2,
          alignItems: 'center',
          gap: 2,
        }}
      >
        <div sx={{ flex: 1 }}>
          <CatalogItemAutocomplete
            placeholderText="1. Search for a catalog item"
            initialTextValue={initialTextValue}
            selectedItemId={selectedItemId}
            onSelect={onItemSelect}
            selectTextOnFocus={true}
          />
        </div>
        {!selectedItemId ? null : (
          <Link
            sx={{ flex: 0, color: 'secondaryDefault' }}
            to={`/item/${selectedItemId}`}
          >
            Item&nbsp;Details
          </Link>
        )}
      </div>

      {selectedItemId ? (
        <>
          <Box sx={{ marginY: 4 }}>
            <LocationAutocomplete
              resetOnSelect={true}
              placeholderText={locationAcPlaceholderText}
              onSelect={onLocationSelect}
              selectTextOnFocus={true}
              isDisabled={isLocationAcDisabled}
            />
          </Box>
          <Divider variant="forms.itemUpsert.divider" />
        </>
      ) : null}
      {newLocations.length ? (
        <>
          <Text sx={{ variant: 'text.labelLg' }}>
            Recently Added Locations ({newLocations.length})
          </Text>
          <Divider variant="forms.itemUpsert.divider" />
          {newLocations.map((v, index) => {
            const locationId = v.value as string;
            return (
              <LocationItem
                key={index}
                locationId={locationId}
                itemId={selectedItemId || ''}
                locationName={v.label}
                onRemove={(locationId) =>
                  removeResetLocationItemHandler(locationId, true)
                }
              />
            );
          })}
          <Divider variant="forms.itemUpsert.divider" />
        </>
      ) : null}

      <LocationItemsByItemId
        itemId={selectedItemId}
        onRemove={removeResetLocationItemHandler}
      />
    </TwoColLayout>
  );
};

export default LocationItemManager;
