import {
  Category,
  Currency,
  Money,
  Unit,
  UnitType,
} from '@/graphql/types/generated';

export const emptyUnit = {
  id: '',
  conversionRate: 1,
  name: '',
  unitType: UnitType.Case,
} as unknown as Unit;

export const emptyCategory = {
  id: null,
  name: '',
  createdBy: null,
  createdAt: null,
  subcategories: [],
  position: 0,
} as unknown as Category;

export const emptyPricing: Money = {
  amount: null,
  cents: NaN,
  currencyISO: Currency.Usd,
  currency: Currency.Usd,
};
