import { CatalogStatus } from '@/graphql/types/generated';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';

const itemIsEnabledInCatalog = 'Item is enabled in catalog';
const searchForLocationToAdd = 'Search for location to add';
export const getLocationAcPlaceHolderText = (catalogStatus?: CatalogStatus) => {
  return catalogStatus === 'IN_CATALOG'
    ? itemIsEnabledInCatalog
    : searchForLocationToAdd;
};

export const SelectTextOnFocus = (
  e: NativeSyntheticEvent<TextInputFocusEventData>,
) => {
  const input = e.target as unknown as HTMLInputElement;
  input?.select();
};
