import Search from '../assets/images/Search.svg';
import React, { InputHTMLAttributes } from 'react';
import { Input } from 'theme-ui';
import { useAppDispatch } from '@/hooks/store';
import { filterActions } from '@/state/Filters';

type SearchBarProps = {
  placeholder?: string;
  input: InputHTMLAttributes<HTMLInputElement>;
};

function Searchbar({ placeholder, input: { value } }: SearchBarProps) {
  const dispatch = useAppDispatch();

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value === '') {
      dispatch(filterActions.resetSearchQuery());
    } else {
      dispatch(filterActions.setSearchQuery(event.currentTarget.value));
    }
  };

  const inputTheme = {
    padding: '10px 10px 10px 40px',
    textalign: 'center',
    background: `url(${Search}) no-repeat`,
    backgroundPosition: '10px 8px',
    borderRadius: 0,
    border: 'none',
  };

  return (
    <Input
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      sx={inputTheme}
    />
  );
}

export default Searchbar;
