import { ThemeUICSSObject } from 'theme-ui';

export const MarginYAuto: ThemeUICSSObject = {
  marginTop: 'auto',
  marginBottom: 'auto',
};

export const MarginXAuto: ThemeUICSSObject = {
  marginLeft: 'auto',
  marginRight: 'auto',
};

export const TextCenter: ThemeUICSSObject = {
  textAlign: 'center',
};
