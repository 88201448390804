import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ModalState = {
  isVisible: boolean;
  currentScreenID: string;
};

const initialState: ModalState = {
  isVisible: false,
  currentScreenID: '',
};

const ModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    hideModal: (state) => {
      state.isVisible = false;
    },
    setCurrentScreenId: (state, action: PayloadAction<string>) => {
      state.currentScreenID = action.payload;
    },
    showModal: (state) => {
      state.isVisible = true;
    },
  },
});

const { reducer, actions } = ModalSlice;

export const { hideModal, setCurrentScreenId, showModal } = actions;

export default reducer;
