import TwoColLayout from '@/layouts/two-col';
import { useEffect } from 'react';
import ErrorBanner from '@/components/ErrorBanner';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { ErrorActions } from '@/state/errors';
import { brandsActions } from '@/state/brands';
import Header from '@/components/Brand/Header';
import { filterActions } from '@/state/Filters';
import BrandTableContainer from '@/components/Brand/BrandTableContainer';
import CreateBrandModal from '@/components/Brand/CreateBrandModal';
import EditBrandModal from '@/components/Brand/EditBrandModal';
import DeleteBrandModal from '@/components/Brand/DeleteBrandModal';
import SearchBar from '@/components/Brand/SearchBar';

const BrandManagerIndex = () => {
  const dispatch = useAppDispatch();

  const editingBrand = useAppSelector((state) => state.brands.editing);
  const deletingBrand = useAppSelector((state) => state.brands.deleting);
  const isCreateModalVisible = useAppSelector(
    (state) => state.brands.isCreateModalVisible,
  );

  useEffect(() => {
    dispatch(ErrorActions.resetErrors());
    dispatch(filterActions.resetState());
  }, []);

  const handleSubmit = (): void => {
    dispatch(brandsActions.resetBrands());
    dispatch(brandsActions.executeSearch());
    dispatch(brandsActions.resetModifyingBrand());
  };

  const handleClose = (): void => {
    dispatch(brandsActions.resetModifyingBrand());
  };

  const isEditModalVisible = !!editingBrand;
  const isDeleteModalVisible = !!deletingBrand;

  return (
    <TwoColLayout>
      <Header />
      <ErrorBanner />
      <SearchBar />
      <BrandTableContainer />
      <CreateBrandModal
        visible={isCreateModalVisible}
        submit={handleSubmit}
        close={handleClose}
      />
      <EditBrandModal
        visible={isEditModalVisible}
        submit={handleSubmit}
        brand={editingBrand}
        close={handleClose}
      />
      <DeleteBrandModal
        visible={isDeleteModalVisible}
        submit={handleSubmit}
        brand={deletingBrand}
        close={handleClose}
      />
    </TwoColLayout>
  );
};

export default BrandManagerIndex;
