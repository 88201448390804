import Modal from '@/components/Modal';
import { Flex, Heading, Input, Paragraph, Text } from 'theme-ui';
import { Button } from '@odekoteam/doppio';
import { EditingBrandState } from '../../state/brands/types';
import { ModalHeaderStyle, ModalFooterActions } from './Styles';
import React, { useEffect, useState } from 'react';
import { isEqualCaseSensitive } from '@/utils/str';
import { useDeleteItemBrandMutation } from '@/graphql/types/generated';
import ModalErrorBanner from './ModalErrorBanner';

const DeleteBrandModal = ({
  visible,
  brand,
  close,
  submit,
}: {
  visible: boolean;
  brand?: EditingBrandState;
  submit: () => void;
  close: () => void;
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [brandName, setBrandName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [, deleteItemBrandMutation] = useDeleteItemBrandMutation();

  const resetState = () => {
    setBrandName('');
    setErrors([]);
  };

  const handleCancel = () => {
    resetState();
    close();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrandName(e.target.value);
  };

  const handleSubmit = async () => {
    const submitErrors: string[] = [];

    try {
      setIsSubmitting(true);
      const { data, error } = await deleteItemBrandMutation({
        input: {
          id: brand!.id,
        },
      });
      setIsSubmitting(false);
      if (error) {
        submitErrors.push(error.message);
      }

      data?.deleteItemBrand?.errors.forEach((error) => {
        submitErrors.push(error.message);
      });
    } catch (e: any) {
      submitErrors.push(e.message);
    }

    setErrors(submitErrors);
    if (submitErrors.length === 0) {
      submit();
      resetState();
    }
  };

  useEffect(() => {
    if (isSubmitting) {
      setIsDisabled(true);
    } else if (brand?.name && isEqualCaseSensitive(brandName, brand?.name)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [brandName]);

  return (
    <Modal visible={visible}>
      <Heading as="h3" sx={ModalHeaderStyle}>
        Delete Brand Name
      </Heading>

      <ModalErrorBanner errors={errors} />

      <Flex sx={{ flexDirection: 'column', gap: 3, marginBottom: 6 }}>
        <Paragraph variant="paragraphMd">
          Are you sure you want to delete{' '}
          <Text variant="paragraphExtraBoldMd">{brand?.name}</Text> brand name?
          This action can create some discrepancy in the catalog. Please make
          sure you double check before you take this action.
        </Paragraph>

        <Paragraph variant="paragraphExtraBoldMd">
          Please write the brand name you are going to delete.
        </Paragraph>
      </Flex>

      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Input
          type="text"
          placeholder="Brand Name"
          value={brandName}
          onChange={handleChange}
        />
      </Flex>

      <Flex sx={ModalFooterActions}>
        <Button variant="ghost" onPress={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onPress={handleSubmit}
          isDisabled={isDisabled}
        >
          Delete
        </Button>
      </Flex>
    </Modal>
  );
};

export default DeleteBrandModal;
