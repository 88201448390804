import { useHasBrandedItems } from '@/hooks/featureflipper';
import { Link } from 'react-router-dom';
import { Box, Paragraph } from 'theme-ui';
import {
  dropdownContainerStyles,
  linkStyles,
  optionContainerStyles,
  titleStyles,
  descriptionStyles,
} from './CreateItemOptionsStyles';

const CreateItemOptions = () => {
  const hasBrandedItems: boolean = useHasBrandedItems();

  const optionData = [
    {
      title: 'Inventory Item',
      description: 'An item for the Supply catalog carried in our warehouse',
      route: '/new/inventory',
    },
    {
      title: 'Non-Inventory Item',
      description: 'An item for the Supply catalog supplied by other vendors',
      route: '/new/vendor',
    },
  ];

  if (hasBrandedItems) {
    optionData.push({
      title: 'In-House Base Branded Item',
      description:
        'An item for the Branded Paper catalog that we print in our warehouse',
      route: '/base_branded_item/new/in_house',
    });

    // we're just focusing on 2.0, this form won't be functional for now
    // optionData.push({
    //   title: 'External Base Branded Item',
    //   description:
    //     "An item for the Branded Paper catalog that's printed by other vendors",
    //   route: '/base_branded_item/new/external',
    // });
  }

  return (
    <Box className="dropdown" sx={dropdownContainerStyles}>
      {optionData.map((data) => (
        <Link
          to={data.route}
          key={data.title}
          sx={{ ...linkStyles, variant: 'styles.a' }}
        >
          <Box sx={optionContainerStyles}>
            <Paragraph sx={titleStyles}>{data.title}</Paragraph>
            <Paragraph sx={descriptionStyles}>{data.description}</Paragraph>
          </Box>
        </Link>
      ))}
    </Box>
  );
};

export default CreateItemOptions;
