import { Warehouse } from '@/graphql/types/generated';
import { Form, Field } from 'react-final-form';
import { Label, Grid } from 'theme-ui';
import { UnorderedListStyle } from '@/components/Index/BulkUploadDropdown/styles';
import InputGroup from '@/components/forms/InputGroup';

const minValue = (min: number) => (value: number) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const formId = 'baseBrandedLeadTime';

export const LeadTimeForm = ({
  warehouses,
  onSubmit,
  minDeliveryTime,
}: {
  warehouses: Warehouse[];
  onSubmit: any;
  minDeliveryTime: number;
}) => (
  <Form
    onSubmit={onSubmit}
    initialValues={{ warehouses }}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} id={formId}>
        <ul sx={{ ...UnorderedListStyle, maxWidth: ' 600px' }}>
          {warehouses.map((warehouse: any, index: number) => (
            <li key={`lead-${index}`} sx={{ marginBottom: '1rem' }}>
              <Grid columns={3} gap={0} variant="Table">
                <Label>{warehouse.name}</Label>
                <Field
                  name={`warehouses[${index}].brandedPaperLeadTime`}
                  validate={minValue(minDeliveryTime)}
                >
                  {({ input, meta }) => (
                    <InputGroup
                      label={warehouse.name}
                      input={input}
                      fieldError={meta.touched && meta.error}
                      type="number"
                      extraProps={{ min: minDeliveryTime }}
                    />
                  )}
                </Field>
              </Grid>
            </li>
          ))}
        </ul>
      </form>
    )}
  />
);

export default LeadTimeForm;
