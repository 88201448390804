import React, { useEffect } from 'react';
import { filterActions } from '@/state/Filters';
import { useAppDispatch } from '@/hooks/store';
import { useGetWarehousesQuery } from '@/graphql/types/generated';
import { ErrorActions } from '@/state/errors';
import { NativeSelect, NativeSelectProps } from '@odekoteam/doppio';

type WarehouseFilterSelectProps = {
  input: { value?: string; name?: string };
  disabled?: boolean;
};

const WarehouseFilterSelect = ({
  input: { value },
  disabled,
}: WarehouseFilterSelectProps) => {
  const dispatch = useAppDispatch();

  const [warehouseResult] = useGetWarehousesQuery();

  useEffect(() => {
    dispatch(ErrorActions.resetErrors());
    if (warehouseResult.error) {
      dispatch(ErrorActions.setGraphqlErrors(warehouseResult.error));
    }
  }, [warehouseResult.error]);

  let warehouseList = warehouseResult.data?.warehouses ?? [];

  let items: NativeSelectProps['items'] = [
    { label: 'All Warehouses', value: '' },
    ...warehouseList.map((o) => ({ label: o.name, value: o.id })),
  ];

  const handleChange: NativeSelectProps['onValueChange'] = (value) => {
    if (value === '') {
      dispatch(filterActions.resetWarehouse());
      dispatch(filterActions.resetCategory());
      dispatch(filterActions.resetSubcategory());
      dispatch(filterActions.resetItemType());
      dispatch(filterActions.resetPrintMethod());
    } else {
      dispatch(filterActions.setWarehouse(value as string));
    }
  };

  return (
    <NativeSelect
      value={value}
      isDisabled={disabled}
      items={items}
      onValueChange={handleChange}
    />
  );
};

export default WarehouseFilterSelect;
