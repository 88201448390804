import { BaseBrandedItem, PrintingMethod } from '@/graphql/types/generated';
import { Link } from 'react-router-dom';
import { Cell, Column } from 'react-table';
import CopyableText from '../CopyableText';
import ImageThumbnail from '../Index/ImageThumbnail';

const ImageCell = ({ value: imageUrls }: Cell) => {
  // placeholder space for items w/ no image
  if (!imageUrls[0]) return <div sx={{ width: '40px', height: '10px' }} />;

  return (
    <ImageThumbnail
      imgUrl={imageUrls[0]}
      styles={{ maxWidth: '40px', maxHeight: '60px' }}
    />
  );
};

export const EditLinkCell = ({ row }: Cell<BaseBrandedItem>) => {
  if (row.original.printingMethod === PrintingMethod.External) {
    return null;
  }

  return (
    <Link
      to={`/base_branded_item/edit/${row.original.id}`}
      sx={{
        variant: 'styles.a',
        color: 'primaryHover',
        fontWeight: 'bold',
        display: 'table',
        margin: '0 auto',
      }}
    >
      ✏️
    </Link>
  );
};

const NameCell = ({ row, value }: Cell<BaseBrandedItem>) => {
  return (
    <div sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div sx={{ fontWeight: 'semibold', color: 'neutralDark' }}>{value}</div>
      <div sx={{ variant: 'text.minorNote' }}>
        <CopyableText
          sx={{ display: 'inline-flex' }}
          title="Click to copy UUID"
        >
          {row.original.id}
        </CopyableText>
      </div>
    </div>
  );
};

export type ItemColumns = BaseBrandedItem & {
  edit?: undefined;
};

export const itemTableColumns: Column<ItemColumns>[] = [
  {
    Header: 'Image',
    accessor: 'imageUrls',
    Cell: ImageCell,
    disableSortBy: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: NameCell,
  },
  {
    Header: 'Unit',
    accessor: (row) => row.salesUnit.name,
    disableSortBy: true,
  },
  {
    Header: 'Category',
    accessor: (row) => row.category.name,
  },
  {
    Header: 'Subcategory',
    accessor: (row) => row.subcategory.name,
  },
  {
    Header: '✏️',
    id: 'edit',
    accessor: 'edit',
    Cell: EditLinkCell,
    disableSortBy: true,
  },
];
