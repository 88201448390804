import { Button, Grid, Box, Flex, Text, Image } from 'theme-ui';
import Searchbar from '../Searchbar';
import { useAppSelector } from '@/hooks/store';
import Union from '@/assets/images/Union.svg';
import { useState, useRef } from 'react';
import WarehouseFilterSelect from '../Index/Filters/WarehouseFilterSelect';
import PrintMethodFilterSelect from './Filters/PrintMethodFilterSelect';
import CategoryFilterSelect from './Filters/CategoryFilterSelect';
import SubcategoryFilterSelect from './Filters/SubcategoryFilterSelect';

const FilterArea = () => {
  const filters = useAppSelector((state) => state.filters);

  const {
    searchQuery,
    selectedCategoryId,
    selectedBrandedPrintMethod,
    selectedWarehouseId,
  } = filters;

  const [toggle, setToggle] = useState(true);

  const ref = useRef<HTMLButtonElement>(null);

  const toggledStyle = {
    bg: 'neutralLight',
    borderColor: 'secondaryHighlight',
    marginLeft: 7,
    color: 'secondaryDefault',
  };

  const defaultToggleStyle = {
    bg: 'secondaryDefault',
    borderColor: 'secondaryDefault',
    marginLeft: 7,
    color: 'neutralLight',
  };
  const toggleStyle = toggle === true ? toggledStyle : defaultToggleStyle;

  const filterCountStyle = {
    display: 'inline-block',
    width: 3,
    height: 3,
    fontSize: 0,
    lineHeight: 0,
    borderRadius: 0,
    color: 'secondaryDefault',
    backgroundColor: 'primaryDefault',
    border: '1px solid source',
    marginLeft: 2,
    textDecoration: 'none',
  };

  const filtersForCount = Object.fromEntries(
    Object.entries(filters).filter(([key]) => key !== 'searchQuery'),
  );
  const activeFilterCount = Object.values(filtersForCount).filter(
    (f) => f !== undefined,
  ).length;

  return (
    <>
      <Flex sx={{ marginTop: 3 }}>
        <Box sx={{ flex: 6 }}>
          <Searchbar
            placeholder="Search by item name"
            input={{ value: searchQuery }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Button
            ref={ref}
            onClick={() => setToggle(!toggle)}
            variant="cta"
            sx={toggleStyle}
          >
            Filters
            {toggle ? (
              <Image src={Union} sx={{ verticalAlign: 'middle', padding: 2 }} />
            ) : (
              <Text sx={filterCountStyle}>{activeFilterCount}</Text>
            )}
          </Button>
        </Box>
      </Flex>
      {toggle && (
        <Grid columns={4} sx={{ marginTop: 3 }}>
          <Box sx={{ display: 'inline-block' }}>
            <WarehouseFilterSelect
              input={{ name: 'Warehouses', value: selectedWarehouseId }}
            />
          </Box>
          <Box>
            <PrintMethodFilterSelect
              input={{ name: 'PrintMethod', value: selectedBrandedPrintMethod }}
            />
          </Box>
          <Box>
            <CategoryFilterSelect
              input={{ name: 'Category', value: selectedCategoryId }}
            />
          </Box>
          <Box>
            <SubcategoryFilterSelect />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default FilterArea;
