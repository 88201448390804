import {
  InventoryWarehouseAssociation,
  NonInventoryWarehouseAssociation,
} from '@/graphql/types/generated';

export type WarehouseAssociation =
  | InventoryWarehouseAssociation
  | NonInventoryWarehouseAssociation;

/**
 * determines if InventoryWarehouseAssociation or NonInventoryWarehouseAssociation
 * and returns appropriate vendorId
 */
export const getVendorId = (wa: WarehouseAssociation): string | undefined => {
  // WarehouseAssociation is a Union so we need to dial the type in
  if (wa.__typename === 'InventoryWarehouseAssociation') {
    return (wa as InventoryWarehouseAssociation).procurementVendor?.id;
  }
  // at this point it's not an InventoryWarehouseAssociation so we have
  // to cast in order to get the typing
  // There's probably a more elegant way of doing this...
  // not sure why normal discriminated union stuff not working...
  return (wa as NonInventoryWarehouseAssociation).vendor?.id;
};

/**
 * determines if InventoryWarehouseAssociation or NonInventoryWarehouseAssociation
 * and returns appropriate vendorName
 */
export const getVendorName = (wa: WarehouseAssociation): string | undefined => {
  // WarehouseAssociation is a Union so we need to dial the type in
  if (wa.__typename === 'InventoryWarehouseAssociation') {
    return (wa as InventoryWarehouseAssociation).procurementVendor?.name;
  }
  // at this point it's not an InventoryWarehouseAssociation so we have
  // to cast in order to get the typing
  // There's probably a more elegant way of doing this...
  // not sure why normal discriminated union stuff not working...
  return (wa as NonInventoryWarehouseAssociation).vendor?.name;
};

/**
 * determines if InventoryWarehouseAssociation or NonInventoryWarehouseAssociation
 * and returns appropriate vendorWarehouseName
 */
export const getVendorWarehousename = (
  wa: WarehouseAssociation,
): string | undefined => {
  if (wa.__typename === 'InventoryWarehouseAssociation') {
    return;
  }
  return (wa as NonInventoryWarehouseAssociation).vendor?.warehouse?.name;
};
