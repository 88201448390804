import { configureStore } from '@reduxjs/toolkit';
import ErrorReducer from './errors';
import FiltersReducer from './Filters';
import ItemsReducer from './items';
import BrandsReducer from './brands';
import BrandFiltersReducer from './brands/filters';
import BaseBrandedItemsReducer from './baseBrandedItems';
import ModalReducer from './modal';
import AuthReducer from './auth';
import ToastReducer from './toast';

const store = configureStore({
  reducer: {
    brands: BrandsReducer,
    brandsFilters: BrandFiltersReducer,
    errors: ErrorReducer,
    filters: FiltersReducer,
    items: ItemsReducer,
    modal: ModalReducer,
    auth: AuthReducer,
    toast: ToastReducer,
    baseBrandedItems: BaseBrandedItemsReducer,
  },
});
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
