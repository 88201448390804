import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { setCurrentScreenId } from '@/state/modal';
import { useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { ThemeUIStyleObject } from 'theme-ui';
import Next from './components/next';
import Screen from './components/screen';
import { modalContentPopCSS, modalOverlayCSS, modalPopCSS } from './styles';

type ModalProps = {
  children?: (JSX.Element | null)[];
  contentStyle?: ThemeUIStyleObject;
  initial?: string;
  parent?: Element | string;
  visible: boolean;
};

const Modal = ({
  children,
  contentStyle,
  initial,
  parent,
  visible,
}: ModalProps) => {
  const dispatch = useAppDispatch();
  const parentEl: Element = useMemo(() => {
    const el =
      typeof parent === 'string' ? document.querySelector(parent) : parent;
    return el || document.body;
  }, [parent]);
  const [screen, setScreen] = useState<JSX.Element | null>();
  const [includesScreens, setIncludesScreens] = useState<boolean>(false);
  const currentScreenID = useAppSelector((state) => {
    return state.modal.currentScreenID || initial || '1';
  });
  const contentStyleSx = useMemo(
    () => ({ ...modalContentPopCSS, ...contentStyle }),
    [contentStyle],
  );

  useEffect(() => {
    // IF no screens exist as a child, just show children
    setIncludesScreens(
      children?.find((child: JSX.Element | null) => child?.props?.screen) !==
        undefined,
    );
  }, [children]);

  useEffect(() => {
    if (!children || !includesScreens) return;

    const [currentScreen] = children.filter((child: JSX.Element | null) => {
      return child?.props?.screen === currentScreenID.toString();
    });

    dispatch(setCurrentScreenId(currentScreenID));
    setScreen(currentScreen);
  }, [children, currentScreenID]);

  return visible
    ? ReactDOM.createPortal(
        <>
          <div sx={modalPopCSS}>
            <div sx={contentStyleSx}>{includesScreens ? screen : children}</div>
          </div>
          <div sx={modalOverlayCSS} />
        </>,
        parentEl,
      )
    : null;
};

Modal.Next = Next;
Modal.Screen = Screen;

export default Modal;
