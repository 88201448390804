import { useAuth0 } from '@auth0/auth0-react';
import { Button, Flex, Heading, Box, Image, Text } from 'theme-ui';
import { Link, useParams } from 'react-router-dom';
import Box2 from '@/assets/images/Box2.svg';
import SnapIconNotFilled from '@/assets/images/SnapIconNotFilled.svg';
import Logout from '@/assets/images/Logout.svg';
import {
  headerTitle,
  sidebarContainerStyles,
  navigationStyles,
  linkStyles,
  imgStyles,
  linkTextStyles,
  homeStyles,
  userImgStyles,
} from '@/components/Index/SidebarStyles';
import { getLocationItemUrl, UrlProps } from '@/pages/LocationItemManager';
import { useIsItemBrandAssociationEnabled } from '../hooks/featureflipper';

const Sidebar = () => {
  const { logout } = useAuth0();
  const { id } = useParams<UrlProps>();
  const isItemBrandAssociationEnabled = useIsItemBrandAssociationEnabled();
  const locationItemManagerUrl = getLocationItemUrl(id);
  return (
    <Flex className="sidebar" sx={sidebarContainerStyles}>
      <Box sx={navigationStyles}>
        <Heading sx={{ ...headerTitle, variant: 'text.headingXs' }}>
          <Image src={SnapIconNotFilled} sx={userImgStyles} alt="" />
          <Text className="title" sx={linkTextStyles}>
            Item Management
          </Text>
        </Heading>
        <Link to="/" sx={linkStyles}>
          <Image src={Box2} sx={imgStyles} />
          <Text className="title" sx={linkTextStyles}>
            Items List
          </Text>
        </Link>
        <Link to="/base_branded_items" sx={linkStyles}>
          <Image src={Box2} sx={imgStyles} />
          <Text className="title" sx={linkTextStyles}>
            Base Branded Items List
          </Text>
        </Link>
        <Link to={locationItemManagerUrl} sx={linkStyles}>
          <Image src={Box2} sx={imgStyles} />
          <Text className="title" sx={linkTextStyles}>
            Location Item Manager
          </Text>
        </Link>
        {isItemBrandAssociationEnabled && (
          <Link to="/brands" sx={linkStyles}>
            <Image src={Box2} sx={imgStyles} />
            <Text className="title" sx={linkTextStyles}>
              Brand Manager
            </Text>
          </Link>
        )}
      </Box>
      <Box sx={homeStyles}>
        <Button
          onClick={() => logout({ returnTo: window.location.origin })}
          sx={{
            ...linkStyles,
            bg: 'neutralLight',
            padding: '0',
            textAlign: 'left',
          }}
        >
          <Image src={Logout} sx={imgStyles} />
          <Text className="title" sx={linkTextStyles}>
            Log Out
          </Text>
        </Button>
      </Box>
    </Flex>
  );
};

export default Sidebar;
