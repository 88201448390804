import { Button } from '@odekoteam/doppio';
import { setCurrentScreenId } from '@/state/modal';
import { useAppDispatch } from '@/hooks/store';

export enum VariantTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Ghost = 'ghost',
  Slay = 'slay',
}

type NextProps = {
  children: string;
  isDisabled?: boolean;
  nextKey: string;
  variant?: VariantTypes;
};

const Next = ({
  children,
  isDisabled = false,
  nextKey,
  variant = VariantTypes.Primary,
}: NextProps) => {
  const dispatch = useAppDispatch();
  const currentScreenID = nextKey;

  const onClick = () => dispatch(setCurrentScreenId(currentScreenID));

  return (
    <Button
      variant={variant}
      isDisabled={isDisabled}
      accessibilityLabel={`${variant} button`}
      onPress={() => onClick()}
    >
      {children}
    </Button>
  );
};

export default Next;
