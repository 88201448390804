import { devtoolsExchange } from '@urql/devtools';
import { createClient, defaultExchanges } from 'urql';

const client = (token: string) => {
  return createClient({
    url: window.env.GRAPHQL_ENDPOINT,
    exchanges: [devtoolsExchange, ...defaultExchanges],
    fetchOptions: () => ({
      headers: {
        Authorization: `Bearer ${token}`,
        'apollographql-client-name': window.env.GRAPHQL_CLIENT_NAME,
        'apollographql-client-version': `${window.env.APP_VERSION}-${window.env.APP_ENV}`,
      },
      credentials: 'include',
    }),
  });
};

export default client;
