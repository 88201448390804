import Modal from '@/components/Modal';
import ModalErrorBanner from './ModalErrorBanner';
import { Flex, Heading, Input } from 'theme-ui';
import { Button } from '@odekoteam/doppio';
import { EditingBrandState } from '../../state/brands/types';
import { ModalHeaderStyle, ModalFooterActions } from './Styles';
import React, { useEffect, useState } from 'react';
import { useUpdateItemBrandMutation } from '../../graphql/types/generated';
import { isEqualCaseSensitive } from '@/utils/str';

const EditBrandModal = ({
  visible,
  brand,
  close,
  submit,
}: {
  visible: boolean;
  brand?: EditingBrandState;
  submit: () => void;
  close: () => void;
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [brandName, setBrandName] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [, itemBrandMutation] = useUpdateItemBrandMutation();

  const resetState = () => {
    setBrandName('');
    setErrors([]);
  };

  const handleCancel = () => {
    resetState();
    close();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrandName(e.target.value);
  };

  const handleSubmit = async () => {
    const submitErrors: string[] = [];

    try {
      setIsSubmitting(true);
      const { data, error } = await itemBrandMutation({
        input: {
          id: brand!.id,
          name: brandName,
        },
      });

      setIsSubmitting(false);
      if (error) {
        submitErrors.push(error.message);
      }

      if (data?.updateItemBrand?.errors) {
        data.updateItemBrand.errors.forEach((error) => {
          submitErrors.push(error.message);
        });
      }
    } catch (e: any) {
      submitErrors.push(e.message);
    }

    setErrors(submitErrors);
    if (submitErrors.length === 0) {
      submit();
      resetState();
    }
  };

  useEffect(() => {
    if (isSubmitting) {
      setIsDisabled(true);
    } else if (brandName.length === 0) {
      setIsDisabled(true);
    } else if (brand?.name && isEqualCaseSensitive(brandName, brand?.name)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [brandName, isSubmitting]);

  return (
    <Modal visible={visible}>
      <Heading as="h3" sx={ModalHeaderStyle}>
        Edit Brand Name
      </Heading>

      <ModalErrorBanner errors={errors} />

      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Input type="text" value={brand?.name} disabled readOnly />
        <Input
          type="text"
          placeholder="New Name"
          value={brandName}
          onChange={handleChange}
        />
      </Flex>

      <Flex sx={ModalFooterActions}>
        <Button variant="ghost" onPress={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onPress={handleSubmit}
          isDisabled={isDisabled}
        >
          Update
        </Button>
      </Flex>
    </Modal>
  );
};

export default EditBrandModal;
