import { Box, Flex, Heading, Paragraph } from 'theme-ui';
import { Button, IconPlus } from '@odekoteam/doppio';
import { useAppDispatch } from '../../hooks/store';
import { brandsActions } from '../../state/brands';

const IndexHeader = () => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(brandsActions.setCreateModalIsVisible());
  };

  return (
    <Flex as="header" sx={{ justifyContent: 'space-between' }}>
      <Box>
        <Heading variant="headingMd">Brand Manager</Heading>
        <Paragraph
          variant="paragraphLg"
          sx={{ color: 'neutralDark', marginTop: '2px' }}
        >
          Edit, Delete and Add Brands
        </Paragraph>
      </Box>
      <Box>
        <Button
          variant="secondary"
          iconRight={<IconPlus />}
          onPress={handleClick}
        >
          Create Brand
        </Button>
      </Box>
    </Flex>
  );
};

export default IndexHeader;
