import { Image, ThemeUICSSObject } from 'theme-ui';

type ImageThumbnailProps = {
  imgUrl: string;
  width?: number;
  styles?: ThemeUICSSObject;
};
const ImageThumbnail = ({
  imgUrl,
  width = 40,
  styles,
}: ImageThumbnailProps) => {
  return (
    <Image src={imgUrl} alt="product-thumbnail" width={width} sx={styles} />
  );
};

export default ImageThumbnail;
