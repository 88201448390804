import {
  ItemsSortOrder,
  ItemType,
  PrintingMethod,
} from '@/graphql/types/generated';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { FiltersState } from './types';

const initialState: FiltersState = {
  searchQuery: '',
  selectedCategoryId: undefined,
  selectedSubcategoryId: undefined,
  selectedWarehouseId: undefined,
  selectedItemType: undefined,
  selectedBrandedPrintMethod: undefined,
  selectedSortOrder: undefined,
};
const sortMapping: any = {
  name: 'Name',
  id: 'Id',
  brandName: 'Brand',
  Category: 'Category',
  updatedAt: 'UpdatedAt',
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setCategory: (state, action: PayloadAction<string>) => {
      state.selectedCategoryId = action.payload;
    },
    setSubcategory: (state, action: PayloadAction<string>) => {
      state.selectedSubcategoryId = action.payload;
    },
    setWarehouse: (state, action: PayloadAction<string>) => {
      state.selectedWarehouseId = action.payload;
    },
    setItemType: (state, action: PayloadAction<ItemType>) => {
      state.selectedItemType = action.payload;
    },
    setPrintMethod: (state, action: PayloadAction<PrintingMethod>) => {
      state.selectedBrandedPrintMethod = action.payload;
    },
    resetSubcategory: (state) => {
      state.selectedSubcategoryId = undefined;
    },
    resetCategory: (state) => {
      state.selectedCategoryId = undefined;
    },
    resetWarehouse: (state) => {
      state.selectedWarehouseId = '';
    },
    resetItemType: (state) => {
      state.selectedItemType = undefined;
    },
    resetPrintMethod: (state) => {
      state.selectedBrandedPrintMethod = undefined;
    },
    resetSearchQuery: (state) => {
      state.searchQuery = '';
    },
    setSortOrderDesc: (state, action: PayloadAction<string>) => {
      const sortDirection = 'Desc';
      const key = sortMapping[action.payload] + sortDirection;

      state.selectedSortOrder =
        ItemsSortOrder[key as keyof typeof ItemsSortOrder];
    },
    setSortOrderAsc: (state, action: PayloadAction<string>) => {
      const sortDirection = 'Asc';
      const key = sortMapping[action.payload] + sortDirection;

      state.selectedSortOrder =
        ItemsSortOrder[key as keyof typeof ItemsSortOrder];
    },
    resetSortOrder: (state) => {
      state.selectedSortOrder = undefined;
    },
    resetState: (state) => {
      state.searchQuery = '';
      state.selectedCategoryId = undefined;
      state.selectedSubcategoryId = undefined;
      state.selectedWarehouseId = '';
      state.selectedItemType = undefined;
      state.selectedBrandedPrintMethod = undefined;
      state.selectedSortOrder = undefined;
    },
  },
});

export const filterActions = filtersSlice.actions;

export default filtersSlice.reducer;
