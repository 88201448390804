import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ItemBrand } from '@/graphql/types/generated';
import { EditingBrandState } from './types';

export type ItemBrandsState = {
  brands: ItemBrand[];
  error: string | null;
  hasNextPage: boolean;
  endCursor: string;
  isDirty: boolean;
  isCreateModalVisible: boolean;
  hasValidationError: boolean;
  submitFailed: boolean;
  deleting?: EditingBrandState;
  editing?: EditingBrandState;
  executeSearch: number;
};

const initialState: ItemBrandsState = {
  brands: [],
  error: null,
  hasNextPage: true,
  endCursor: '',
  isCreateModalVisible: false,
  isDirty: false,
  hasValidationError: false,
  submitFailed: false,
  editing: undefined,
  executeSearch: 0,
};

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    setBrands: (state, action: PayloadAction<ItemBrand[]>) => {
      state.brands = action.payload;
    },
    appendBrands: (state, action: PayloadAction<ItemBrand[]>) => {
      state.brands = [...state.brands, ...action.payload];
    },
    setHasNextPage: (state, action: PayloadAction<boolean>) => {
      state.hasNextPage = action.payload;
    },
    setEndCursor: (state, action: PayloadAction<string>) => {
      state.endCursor = action.payload;
    },
    executeSearch: (state) => {
      state.executeSearch += 1;
    },
    resetEndCursor: (state) => {
      state.endCursor = '';
    },
    resetHasNextPage: (state) => {
      state.hasNextPage = false;
    },
    resetBrands: (state) => {
      state.brands = [];
    },
    isDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
    hasValidationErrors: (state, action: PayloadAction<boolean>) => {
      state.hasValidationError = action.payload;
    },
    submitFailed: (state, action: PayloadAction<boolean>) => {
      state.submitFailed = action.payload;
    },
    setCreateModalIsVisible: (state) => {
      state.isCreateModalVisible = true;
    },
    setBrandToDelete: (state, action: PayloadAction<EditingBrandState>) => {
      state.deleting = action.payload;
    },
    setBrandToEdit: (state, action: PayloadAction<EditingBrandState>) => {
      state.editing = action.payload;
    },
    resetModifyingBrand: (state) => {
      state.isCreateModalVisible = false;
      state.deleting = undefined;
      state.editing = undefined;
    },
  },
});
export const brandsActions = brandsSlice.actions;

export default brandsSlice.reducer;
