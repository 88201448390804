import { Notifier } from '@airbrake/browser';

let airbrake: Notifier | null = null;

export function initErrorReporter() {
  if (window.env.AIRBRAKE_PROJECT_ID && window.env.AIRBRAKE_PROJECT_KEY) {
    airbrake = new Notifier({
      projectId: parseInt(window.env.AIRBRAKE_PROJECT_ID, 10),
      projectKey: window.env.AIRBRAKE_PROJECT_KEY,
    });
  }
}

export function notify(error: any, info: any = null) {
  // Send error to Airbrake
  airbrake?.notify({
    error: error,
    params: { info: info },
  });
}
