import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { IconAlertWarning } from '@odekoteam/doppio';
import { ModalErrorBannerStyle } from './Styles';

const ModalErrorBanner = ({ errors }: { errors: string[] }) => {
  return (
    <>
      {errors.map((error, index) => {
        return (
          <Flex sx={ModalErrorBannerStyle} key={`error-banner-${index}`}>
            <Box sx={{ width: '24px', minWidth: 'auto' }}>
              <IconAlertWarning color="warningIcon" height={24} />
            </Box>
            <Box sx={{ paddingTop: '2px' }}>
              <Text variant="paragraphMd">{error}</Text>
            </Box>
          </Flex>
        );
      })}
    </>
  );
};

export default ModalErrorBanner;
