import ChevronDown from '@/assets/images/ChevronDown.svg';
import ChevronUp from '@/assets/images/ChevronUp.svg';
import {
  BaseBrandedItem,
  GetBaseBrandedItemsQueryVariables,
  useGetBaseBrandedItemsQuery,
} from '@/graphql/types/generated';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { ErrorActions } from '@/state/errors';
import { itemsActions } from '@/state/baseBrandedItems';
import { useEffect, useMemo, useState } from 'react';
import { Cell, useSortBy, useTable } from 'react-table';
import { Box, Link, Spinner, ThemeUICSSObject } from 'theme-ui';
import { ItemColumns, itemTableColumns } from './ItemTableColumns';
import {
  cellStyles,
  headerCellStyles,
  headerStyles,
  indexTableWrapper,
  linkInheritStyles,
  rowStyles,
  spinnerStyle,
  tableStyles,
} from './ItemTableStyles';

const cellRenderer = (
  cell: Cell<ItemColumns, any>,
  style: ThemeUICSSObject,
  link?: string,
) => {
  const cellContents =
    link && cell.column.id !== 'edit' ? (
      <Link href={link} sx={linkInheritStyles}>
        {cell.render('Cell')}
      </Link>
    ) : (
      cell.render('Cell')
    );

  return (
    <td
      {...cell.getCellProps()}
      key={cell.value + cell.column.Header}
      sx={style}
    >
      {cellContents}
    </td>
  );
};

const ItemTableContainer = () => {
  const dispatch = useAppDispatch();
  const items = useAppSelector((state) => state.baseBrandedItems.items);
  const [isRefreshing, setRefreshing] = useState(false);

  const filters = useAppSelector((state) => state.filters);

  const variables: GetBaseBrandedItemsQueryVariables = {
    warehouseId: filters.selectedWarehouseId,
    category: filters.selectedCategoryId,
    subcategory: filters.selectedSubcategoryId,
    printingMethod: filters.selectedBrandedPrintMethod,
    searchQuery: filters.searchQuery,
  };

  const [result] = useGetBaseBrandedItemsQuery({
    variables,
    requestPolicy: 'network-only', // TODO: figure out why it's not getting invoked with cache
  });

  const { fetching, error, data } = result;

  useEffect(() => {
    dispatch(ErrorActions.resetErrors());
    if (error) {
      dispatch(ErrorActions.setGraphqlErrors(error));
    }
  }, [error]);

  // NOTE on useMemo: Used to ensure that data isn't recreated every time the table is rendered
  const columns = useMemo(() => itemTableColumns, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<ItemColumns>(
      {
        columns,
        data: items,
        // we need to tell react-table that we will handle sorting manually
        manualSortBy: true,
        // since sort by is boolean, this allows state to automatically reset if sort by isn't applied
        autoResetSortBy: true,
      },
      useSortBy,
    );

  useEffect(() => {
    if (fetching || !data?.baseBrandedItems) {
      return;
    }

    // At this point, we either have an array of Item or an empty array
    const items = data.baseBrandedItems as BaseBrandedItem[];

    if (isRefreshing) {
      dispatch(itemsActions.setItems(items));
      setRefreshing(false);
    } else {
      dispatch(itemsActions.appendItems(items));
    }
  }, [data?.baseBrandedItems, fetching]);

  useEffect(() => {
    if (data) {
      setRefreshing(true);
      dispatch(itemsActions.resetEndCursor());
    }
  }, [variables]);

  if (error) {
    return <b>There was an error fetching</b>;
  }

  return (
    <Box sx={indexTableWrapper}>
      {fetching ? <Spinner sx={spinnerStyle} /> : null}
      <table {...getTableProps()} sx={tableStyles}>
        <thead sx={headerStyles}>
          {headerGroups.map((headerGroup, idx: number) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                  scope="col"
                  sx={headerCellStyles}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? (column.toggleSortBy, (<img src={ChevronUp} />))
                        : (column.toggleSortBy, (<img src={ChevronDown} />))
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const link = `/base_branded_item/item/${row.original.id}`;
            const cellStyle = Object.assign(cellStyles, {
              opacity: fetching ? 0.2 : 1,
            });
            return (
              <tr {...row.getRowProps()} key={row.id} sx={rowStyles}>
                {row.cells.map((cell) => cellRenderer(cell, cellStyle, link))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default ItemTableContainer;
