import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseBrandedItem } from '@/graphql/types/generated';

export type BaseBrandedItemsState = {
  items: BaseBrandedItem[];
  error: string | null;
  hasNextPage: boolean;
  endCursor: string;
  isDirty: boolean;
  hasValidationError: boolean;
  submitFailed: boolean;
};

const initialState: BaseBrandedItemsState = {
  items: [],
  error: null,
  hasNextPage: true,
  endCursor: '',
  isDirty: false,
  hasValidationError: false,
  submitFailed: false,
};

const itemsSlice = createSlice({
  name: 'baseBrandedItems',
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<BaseBrandedItem[]>) => {
      state.items = action.payload;
    },
    appendItems: (state, action: PayloadAction<BaseBrandedItem[]>) => {
      state.items = [...state.items, ...action.payload];
    },
    setHasNextPage: (state, action: PayloadAction<boolean>) => {
      state.hasNextPage = action.payload;
    },
    setEndCursor: (state, action: PayloadAction<string>) => {
      state.endCursor = action.payload;
    },
    resetEndCursor: (state) => {
      state.endCursor = '';
    },
    resetHasNextPage: (state) => {
      state.hasNextPage = false;
    },
    resetItems: (state) => {
      state.items = [];
    },
    isDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
    hasValidationErrors: (state, action: PayloadAction<boolean>) => {
      state.hasValidationError = action.payload;
    },
    submitFailed: (state, action: PayloadAction<boolean>) => {
      state.submitFailed = action.payload;
    },
  },
});
export const itemsActions = itemsSlice.actions;

export default itemsSlice.reducer;
