import { Autocomplete } from '@odekoteam/doppio';
import { useState } from 'react';
import {
  LocationsSearchQueryVariables,
  useLocationsSearchQuery,
} from '@/graphql/types/generated';
import { AutocompleteOption } from '@doppio/elements/build/Autocomplete/AutocompleteList';
import { MarginYAuto } from '@/components/UpsertBaseBrandedItem/utils/tableStyles';
import { SelectTextOnFocus } from '@/components/LocationItemManager/utils';

type Props = {
  onSelect: (item: AutocompleteOption) => void;
  selectTextOnFocus?: boolean;
  initialTextValue?: string;
  placeholderText?: string;
  resetOnSelect?: boolean;
  isDisabled?: boolean;
};

const queryLocations = (
  variables: LocationsSearchQueryVariables,
): AutocompleteOption[] => {
  const [result] = useLocationsSearchQuery({
    variables,
    requestPolicy: 'network-only',
    pause: !variables.name || variables.name.length < 3,
  });
  if (!variables.name) {
    return [];
  }
  const itemList = result.data?.viewer?.locations?.nodes || [];
  const filtered = itemList?.filter((x) => x !== null);
  return filtered?.map((x) => ({
    label: `${x!.businessName}`,
    value: x!.id,
  }));
};
const LocationAutocomplete = ({
  onSelect,
  selectTextOnFocus = false,
  initialTextValue = '',
  resetOnSelect = false,
  placeholderText = 'Search for a location',
  isDisabled,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState(initialTextValue);
  const [selectedItem, setSelectedItem] = useState<AutocompleteOption>();

  const variables: LocationsSearchQueryVariables = {
    first: 30,
    name: searchQuery,
  };

  const selectTextOnFocusHandler = selectTextOnFocus
    ? SelectTextOnFocus
    : undefined;

  return (
    <div sx={MarginYAuto}>
      <Autocomplete
        onFocus={selectTextOnFocusHandler}
        onBlur={() => {
          if (!selectedItem) {
            setSearchQuery('');
            return;
          }

          if (searchQuery.length === 0) {
            // field has been clea, reset selectedItem
            setSelectedItem(undefined);
            return;
          }
        }}
        onSelect={(option) => {
          onSelect(option);
          if (resetOnSelect) {
            setSearchQuery('');
            setSelectedItem(undefined);
          } else {
            setSelectedItem(option);
          }
        }}
        isDisabled={isDisabled}
        value={searchQuery}
        onScroll={function noRefCheck() {}}
        options={queryLocations(variables)}
        placeholder={placeholderText}
        onChangeText={(text) => {
          setSearchQuery(text);
        }}
      />
    </div>
  );
};

export default LocationAutocomplete;
