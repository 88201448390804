import UnsavedChangesModal from '@/components/forms/UnsavedChangesModal';
import { Box, Close, Flex, Text, ThemeUICSSObject } from 'theme-ui';
import { ReactNode } from 'react';
import useModal from '@/components/useModal';
import { useAppSelector } from '@/hooks/store';
import { TOP_OFFSET } from '@/components/DevelopmentEnvironmentBanner';

type TwoColStickyHeaderLayoutProps = {
  content?: ReactNode;
  sidebar: ReactNode;
  cta: ReactNode;
  headerText: string;
  handleClose: () => void;
};

const TwoColStickyHeaderLayout = ({
  sidebar,
  content,
  cta,
  headerText,
  handleClose,
}: TwoColStickyHeaderLayoutProps) => {
  const isDirty = useAppSelector((state) => state.items.isDirty);

  const closeStyles = {
    cursor: 'pointer',
    flex: '0 0 auto',
    marginLeft: '86px',
    marginRight: '22px',
  };

  const headerContainerStyles: ThemeUICSSObject = {
    alignItems: 'center',
    borderBottom: '1px solid #E6E2DA',
    backgroundColor: 'white',
    height: '80px',
    width: '100%',
    position: 'fixed',
    top: `${TOP_OFFSET}px`,
    left: 0,
    zIndex: 10,
    display: 'flex',
  };
  const ctaContainerStyles: ThemeUICSSObject = {
    flex: '0 0 auto',
    marginLeft: 'auto',
    marginRight: '80px',
  };

  const contentStyles: ThemeUICSSObject = {
    flex: '1',
    paddingX: '15px',
    maxWidth: '1024px',
    margin: ['0', '0', '0 auto'],
    display: 'flex',
    padding: `${TOP_OFFSET + 120}px 15px 75px`,
  };
  const sidebarStyles: ThemeUICSSObject = {
    display: ['none', 'flex'],
    marginLeft: '140px',
    position: 'sticky',
    top: `${TOP_OFFSET + 120}px`,
    height: '100%',
  };

  const { toggle, visible } = useModal();

  return (
    <Flex>
      <Box sx={headerContainerStyles}>
        <Close
          sx={closeStyles}
          onClick={() => {
            isDirty ? toggle() : handleClose();
          }}
        />
        <Text sx={{ variant: 'text.headingSm' }}>{headerText}</Text>
        <Box sx={ctaContainerStyles}>{cta}</Box>
        <UnsavedChangesModal
          visible={visible}
          toggle={toggle}
          handleClose={handleClose}
        />
      </Box>
      <Box sx={sidebarStyles}>{sidebar}</Box>
      <Box sx={contentStyles}>{content}</Box>
    </Flex>
  );
};

export default TwoColStickyHeaderLayout;
