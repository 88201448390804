import Modal from '@/components/Modal';
import { Flex, Heading, Input } from 'theme-ui';
import { Button } from '@odekoteam/doppio';
import { ModalHeaderStyle, ModalFooterActions } from './Styles';
import React, { useEffect, useState } from 'react';
import { isEqualCaseSensitive } from '@/utils/str';
import { useCreateItemBrandMutation } from '@/graphql/types/generated';
import ModalErrorBanner from './ModalErrorBanner';

const CreateBrandModal = ({
  visible,
  close,
  submit,
}: {
  visible: boolean;
  submit: () => void;
  close: () => void;
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [brandName, setBrandName] = useState('');
  const [confirmedBrandName, setConfirmedBrandName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [, createBrand] = useCreateItemBrandMutation();

  const resetState = () => {
    setBrandName('');
    setConfirmedBrandName('');
    setErrors([]);
  };

  const handleCancel = () => {
    resetState();
    close();
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrandName(e.target.value);
  };

  const handleConfirmedNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmedBrandName(e.target.value);
  };

  const handleSubmit = async () => {
    const submitErrors: string[] = [];

    try {
      setIsSubmitting(true);
      const { error, data } = await createBrand({
        input: {
          name: brandName,
        },
      });
      setIsSubmitting(false);
      if (error) {
        submitErrors.push(error.message);
      }

      data?.createItemBrand?.errors.forEach((error) => {
        submitErrors.push(error.message);
      });
    } catch (e: any) {
      submitErrors.push(e.message);
    }

    setErrors(submitErrors);
    if (submitErrors.length === 0) {
      submit();
      resetState();
    }
  };

  useEffect(() => {
    if (isSubmitting) {
      setIsDisabled(true);
    } else if (brandName.length === 0 || confirmedBrandName.length === 0) {
      setIsDisabled(true);
    } else if (isEqualCaseSensitive(brandName, confirmedBrandName)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [brandName, confirmedBrandName]);

  return (
    <Modal visible={visible}>
      <Heading as="h3" sx={ModalHeaderStyle}>
        Create Brand Name
      </Heading>

      <ModalErrorBanner errors={errors} />

      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Input
          type="text"
          placeholder="Brand Name"
          value={brandName}
          onChange={handleNameChange}
        />
        <Input
          type="text"
          placeholder="Repeat Name"
          value={confirmedBrandName}
          onChange={handleConfirmedNameChange}
        />
      </Flex>

      <Flex sx={ModalFooterActions}>
        <Button variant="ghost" onPress={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onPress={handleSubmit}
          isDisabled={isDisabled}
        >
          Create
        </Button>
      </Flex>
    </Modal>
  );
};

export default CreateBrandModal;
