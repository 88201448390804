import {
  useLocationQuery,
  useRemoveLocationItemMutation,
} from '@/graphql/types/generated';
import { spinnerStyle } from '@/components/BaseBrandedItemIndex/ItemTableStyles';
import { Flex, Spinner, Text } from 'theme-ui';
import CopyableText from '@/components/CopyableText';
import { Button } from '@odekoteam/doppio';
import { VariantTypes } from '@/components/Modal/components/next';
import React from 'react';
import { locationNameStyle } from '@/pages/LocationItemManager';

type Props = {
  itemId: string;
  locationId: string;
  onRemove?: (locationId: string) => any;
  locationName?: string;
};
const LocationItem = ({
  itemId,
  locationId,
  onRemove,
  locationName,
}: Props) => {
  const [locationNameState] = useLocationQuery({
    variables: { odekoUuid: locationId },
    pause: !!locationName,
  });

  const [, removeLocationItemMutation] = useRemoveLocationItemMutation();
  const removeLocationItem = (locationId: string) => {
    if (itemId && locationId) {
      removeLocationItemMutation({ input: { locationId, itemId } })
        .then((r) => {
          if (onRemove && !r.error && r.data?.removeLocationItem) {
            onRemove(locationId);
          }
        })
        .catch(() => {});
    }
  };

  const locationComponent = locationNameState.fetching ? (
    <Spinner sx={spinnerStyle} />
  ) : (
    <Text sx={locationNameStyle}>
      {locationName ||
        locationNameState?.data?.location?.businessName ||
        'unknown'}
    </Text>
  );

  return (
    <Flex sx={{ flexDirection: 'row', marginY: 2 }}>
      <Flex sx={{ flexDirection: 'column', flex: '1', marginEnd: 2 }}>
        {locationComponent}
        <div sx={{ variant: 'text.minorNote' }}>
          <CopyableText
            sx={{ display: 'inline-flex' }}
            title="Click to copy UUID"
          >
            {locationId}
          </CopyableText>
        </div>
      </Flex>
      {onRemove ? (
        <Button
          variant={VariantTypes.Slay}
          onPress={() => removeLocationItem(locationId)}
        >
          Remove
        </Button>
      ) : null}
    </Flex>
  );
};

export default LocationItem;
