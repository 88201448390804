import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import { Flex, Paragraph, Button, Image, Text, Heading } from 'theme-ui';
import Logout from '@/assets/images/Logout.svg';
import { setToken, setUser } from '@/state/auth';
import { useAppDispatch, useAppSelector } from '@/hooks/store';

type AdminUserLoginCheckerProps = {
  children: JSX.Element;
};

const AdminUserLoginChecker = ({ children }: AdminUserLoginCheckerProps) => {
  const isAdmin = useAppSelector((state) => state.auth.isAdmin);
  const dispatch = useAppDispatch();
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  }: Auth0ContextInterface<User> = useAuth0();
  if (isLoading) {
    return null;
  } else if (!isAuthenticated) {
    loginWithRedirect().then(() => {});
    return null;
  } else {
    getAccessTokenSilently().then((t) => {
      dispatch(setToken(t));
      dispatch(setUser(user!));
    });
    if (!isAdmin) {
      // dont log them out, show forbidden & logout button
      return (
        <Flex
          sx={{
            margin: 0,
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Heading>
            <Text>Forbidden</Text>
          </Heading>

          <Paragraph sx={{ margin: 4, textAlign: 'center', maxWidth: '720px' }}>
            You don’t have the admin credentials necessary to access to this
            site. Please sign-in with admin credentials on the login page or
            contact your manager to determine if you should receive access.
          </Paragraph>

          <Button
            onClick={() => logout({ returnTo: window.location.origin })}
            variant="cta"
            sx={{
              bg: 'neutralLight',
              '&:hover': {
                backgroundColor: 'secondaryHighlight',
              },
            }}
          >
            <Image src={Logout} />
            <Text
              className="title"
              sx={{
                paddingLeft: 3,
                color: 'secondaryDefault',
              }}
            >
              Log Out
            </Text>
          </Button>
        </Flex>
      );
    }
  }
  return children;
};

export default AdminUserLoginChecker;
