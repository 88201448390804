import React from 'react';
import { Flex, Heading } from 'theme-ui';
import { Button, IconPlus, Popover } from '@odekoteam/doppio';
import CreateItemOptions from '@/components/Index/CreateItemOptions';
import { headerStyles } from '@/components/Index/HeaderStyles';

type Props = {
  headerText: string;
  showCreate?: boolean;
  children?: React.ReactNode;
};
const ImsPageHeader = ({ headerText, showCreate = true, children }: Props) => {
  const createComp = !showCreate ? null : (
    <Popover.Root>
      <Popover.Trigger>
        <Button variant="secondary" iconRight={<IconPlus />}>
          Create Item
        </Button>
      </Popover.Trigger>
      <Popover.Content align="right">
        <CreateItemOptions />
      </Popover.Content>
    </Popover.Root>
  );

  return (
    <Flex as="header" sx={headerStyles}>
      <Heading variant="headingMd">{headerText}</Heading>
      {children}
      <Flex>{createComp}</Flex>
    </Flex>
  );
};

export default ImsPageHeader;
