import type { Theme } from 'theme-ui';

// From https://theme-ui.com/guides/typescript/
const makeTheme = <T extends Theme>(t: T) => t;

// NOTE: How to use the properties defined in the theme file
// https://theme-ui.com/sx-prop#theme-aware-properties

export const theme = makeTheme({
  fonts: {
    body: 'Roobert-Regular',
    heading: 'ResidenzGrotesk-Medium',
    monospace: 'Roobert',
  },
  fontSizes: [12, 14, 16, 20, 24, 32],
  fontWeights: {
    regular: 400,
    semibold: 500,
    bold: 700,
    extrabold: 900,
  },
  // NOTE: Design prefers to use absolute values for line-height rather than percentages.
  lineHeights: ['16px', '20px', '24px', '32px', '40px'],
  space: [
    0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80, 96, 112, 128, 144,
  ],
  radii: [0, 4, 8, 16, 32, 40, 48, 56, 64],
  sizes: [0, 4, 8, 16, 32, 40, 48, 56, 64],
  colors: {
    neutralDark: '#050400',
    neutralLight: '#FFFFFF',
    backgroundWeak: '#F4F3EF',
    primaryHover: '#E0A100',
    primaryDefault: '#FFD100',
    primaryPressed: '#FBE8A8',
    primaryHighlight: '#FFF5D6',
    secondaryHover: '#291007',
    secondaryDefault: '#40180B',
    secondaryLabel: '#642511',
    secondaryPressed: '#C0BAAE',
    secondaryDisabled: '#E6E2DA',
    secondaryHighlight: '#EEEBE6',
    warningHover: '#AE4B00',
    warningDefault: '#FF760F',
    warningPressed: '#FFCFAB',
    warningHighlight: '#FFECDE',
    successHover: '#617103',
    successDefault: '#ACC806',
    successPressed: '#E2ECA8',
    successHighlight: '#F4F7DF',
    informationHover: '#1868C7',
    informationDefault: '#589FF1',
    informationPressed: '#B2DBFF',
    informationHighlight: '#E5F4FF',
    errorHover: '#8B1407',
    errorDefault: '#F4230B',
    errorPressed: '#FFD6D1',
    errorHighlight: '#FFF3F2',
    primaryDisabled: '#F4F3EF',
    secondaryText: '#615E54',
    itemTagDefault: '#f5f5f5',
    tertiaryText: '#FFFFDF',
  },
  text: {
    headingXs: {
      fontSize: 2,
      fontWeight: 'bold',
      lineHeight: 2,
    },
    headingSm: {
      fontSize: '18px',
      fontWeight: 'semibold',
      lineHeight: 2,
    },
    headingMd: {
      fontSize: 4,
      fontWeight: 'bold',
      lineHeight: 3,
    },
    headingLg: {
      fontSize: 5,
      fontWeight: 'bold',
      lineHeight: 4,
    },
    paragraphSm: {
      fontSize: 0,
      lineHeight: 1,
    },
    paragraphMd: {
      fontSize: 1,
      lineHeight: 1,
    },
    paragraphLg: {
      fontSize: 2,
      lineHeight: 2,
    },
    paragraphXl: {
      fontSize: 3,
      lineHeight: 2,
    },
    paragraphBoldSm: {
      variant: 'paragraphSm',
      fontWeight: 'bold',
    },
    paragraphBoldMd: {
      variant: 'paragraphMd',
      fontWeight: 'bold',
    },
    paragraphBoldLg: {
      variant: 'paragraphLg',
      fontWeight: 'bold',
    },
    paragraphBoldXl: {
      variant: 'paragraphXl',
      fontWeight: 'bold',
    },
    paragraphExtraBoldMd: {
      variant: 'paragraphMd',
      fontWeight: 'extrabold',
      fontFamily: 'Roobert-Bold',
    },
    labelSm: {
      fontSize: 0,
      lineHeight: 0,
      fontWeight: 'semibold',
    },
    labelMd: {
      fontSize: 1,
      lineHeight: 1,
      fontWeight: 'semibold',
    },
    labelLg: {
      fontSize: 2,
      lineHeight: 1,
      fontWeight: 'semibold',
    },
    labelCapsSm: {
      textTransform: 'uppercase',
      fontSize: 0,
      lineHeight: 0,
      fontWeight: 'semibold',
    },
    labelCapsLg: {
      textTransform: 'uppercase',
      fontSize: 1,
      lineHeight: 1,
      fontWeight: 'semibold',
    },
    minorNote: {
      color: 'gray',
      fontSize: 0,
    },
  },
  styles: {
    // https://theme-ui.com/theme-spec/#styles
    root: {
      fontFamily: 'body',
      fontSize: 1,
      fontWeight: 'regular',
      height: '100%',
      width: '100%',
    },
    // NOTE: More global styles in App.tsx. ThemeUI only exposes root for the
    // html tag, but nothing for the body or elements outside of the app which
    // are the target elements
    a: {
      textDecoration: 'none',
      color: 'neutralDark',
    },
    link: {
      textDecoration: 'underline',
      color: 'neutralDark',
      cursor: 'pointer',
    },
  },
  buttons: {
    primary: {
      fontFamily: 'body',
      bg: '#40180B',
      color: 'neutralLight',
      p: 3,
      border: '2px solid',
      borderColor: 'neutralLight',
      borderRadius: 0,
      cursor: 'pointer',
      height: 5,
      fontSize: 2,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
    },
    btn_outline: {
      variant: 'buttons.primary',
      bg: 'neutralLight',
      color: 'text',
      border: '2px solid',
      borderColor: 'secondaryDisabled',
      borderRadius: 0,
    },
    cta: {
      variant: 'buttons.primary',
      bg: '#40180B',
      borderColor: '#40180B',
      borderRadius: 0,
    },
    headerCta: {
      variant: 'forms.inputs',
      bg: '#FFD100',
      border: 0,
      borderColor: '#FFD100',
      borderRadius: '0',
      color: 'secondaryDefault',
      fontWeight: 'bold',
      paddingX: '16px',
      '&:hover': {
        bg: 'primaryHover',
      },
    },
    btn_icon: {
      variant: 'buttons.primary',
      paddingLeft: 4,
      paddingRight: 3,
      paddingTop: 2,
      paddingBottom: 2,
    },
    btn_disabled: {
      variant: 'buttons.primary',
      marginTop: '4',
      backgroundColor: 'secondaryDisabled',
      fontWeight: 'bold',
      color: 'secondaryLabel',
    },
    btn_clear: {
      variant: 'buttons.primary',
      bg: 'neutralLight',
      color: 'secondaryDefault',
      border: '2px solid',
      borderRadius: 0,
    },
    close: {
      fontSize: '2',
      fontWeight: 'bold',
      bg: 'transparent',
      color: 'neutralDark',
      cursor: 'pointer',
    },
    transparent: {
      bg: 'transparent',
      color: 'neutralDark',
      cursor: 'pointer',
    },
  },
  forms: {
    label: {
      display: 'inline-block',
      padding: '0 5px 0 0',
      width: 'auto',
    },
    select: {
      borderRadius: 0,
      border: 'secondaryHighlight',
    },
    inputs: {
      fontFamily: 'body',
      fontSize: '14px',
      height: '40px',
      borderRadius: 0,
      padding: 2,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'secondaryDisabled',
      ':disabled': {
        bg: 'backgroundWeak',
        font: 'neutralDark',
        cursor: 'not-allowed',
        border: '1px solid #E6E2DA',
        '&:hover': {
          bg: 'secondaryHighlight',
        },
      },
      '&:hover': {
        borderColor: 'primaryHover',
      },
      ':focus': {
        outline: 'none',
      },
      ':focus-visible': {
        outline: 'none',
      },
    },
    input: {
      variant: 'forms.inputs',
    },
    itemUpsert: {
      priceInput: {
        variant: 'forms.inputs',
        width: '100%',
        fontFamily: 'body',
        fontSize: 1,
        fontWeight: 'regular',
      },
      description: {
        borderColor: 'secondaryDisabled',
        variant: 'forms.inputs',
        borderRadius: 0,
        minHeight: '240px',
      },
      paddingBottom: '6em',
      divider: {
        color: '#E4E4E4',
        margin: '1.5em 0',
      },
      heading: {
        margin: '0px 0px 8px 0px',
        fontSize: '18px',
        fontWeight: 'extrabold',
        lineHeight: '26px',
        fontFamily: 'Roobert-Medium',
      },
      subheading: {
        // margin: '0px 0px px 0px',
        fontSize: '18px',
        fontWeight: 'regular',
        lineHeight: '26px',
      },
      paragraph: {
        padding: '8px 0px 20px',
      },
      select: {
        variant: 'forms.inputs',
      },
      selectContainer: {
        margin: '2px 4px',
      },
      selectTitle: {
        bg: 'backgroundWeak',
        padding: '8px',
        paddingX: '1em',
        marginBottom: '0.5em',
      },
      statusButton: {
        borderRadius: 0,
        border: '1px solid #E6E2DA',
        padding: '10px 16px',
        color: 'neutralDark',
        backgroundColor: 'transparent',
      },
    },
  },
  readOnly: {
    heading: {
      marginTop: '16px',
      fontSize: '20px',
      fontWeight: 'normal',
      lineHeight: '24px',
    },
    subheading: {
      marginTop: '16px',
      marginBottom: '0px',
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: '400',
      fontFamily: 'Roobert-Medium',
    },
    divider: {
      marginTop: '8px',
      marginBottom: '16px',
      color: '#E4E4E4',
    },
  },
  table: {
    createItem: {
      borderCollapse: 'collapse',
      minWidth: '100%',
    },
  },
  textarea: {
    minHeight: '90%',
    borderRadius: 0,
    borderColor: 'secondaryDisabled',
  },
  td: {
    paddingX: 3,
    paddingY: 2,
  },
  th: {
    paddingX: 3,
    paddingY: 2,
    textAlign: 'left',
    color: 'neutralDark',
    fontWeight: 'regular', // NOTE: <th> has a bold font weight by default
  },
  thead: {
    bg: 'backgroundWeak',
  },
  tr: {
    borderBottom: '1px solid',
    borderColor: 'secondaryHighlight',
    color: 'neutralDark',
  },
  checkbox: {
    color: 'secondaryDefault',
  },
});

export type TypedTheme = typeof theme;
