import ChevronDown from '@/assets/images/ChevronDown.svg';
import ChevronUp from '@/assets/images/ChevronUp.svg';
import { validate as validateUuid } from 'uuid';
import {
  FiltersStringOps,
  IndexTableBrandsQueryVariables,
  ItemBrandEdge,
  useIndexTableBrandsQuery,
} from '@/graphql/types/generated';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { brandFilterActions } from '@/state/brands/filters';
import { ErrorActions } from '@/state/errors';
import { brandsActions } from '@/state/brands';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Cell, useSortBy, useTable } from 'react-table';
import { Box, Button, Flex, Spinner, ThemeUICSSObject } from 'theme-ui';
import { ItemBrandColumns, brandTableColumns } from './BrandTableColumns';
import {
  cellStyles,
  headerCellStyles,
  headerStyles,
  indexTableWrapper,
  rowStyles,
  spinnerStyle,
  tableStyles,
} from '@/components/Index/ItemTableStyles';
import { tableLinkStyles } from '@/components/Brand/Styles';

const cellRenderer = (
  cell: Cell<ItemBrandColumns, any>,
  style: ThemeUICSSObject,
  dispatch?: Dispatch<any>,
) => {
  const isActions = cell.column.id === 'actions';
  return (
    <td
      {...cell.getCellProps()}
      key={cell.value + cell.column.Header}
      sx={{ ...style, width: 100 / brandTableColumns.length + '%' }}
    >
      {isActions && (
        <Flex sx={{ justifyContent: 'flex-end' }}>
          <Flex sx={{ width: '200px' }}>
            <Button
              sx={tableLinkStyles}
              onClick={() => {
                if (!dispatch) return;
                dispatch(brandsActions.setBrandToEdit(cell.row.original));
              }}
            >
              Edit
            </Button>
            <Button
              sx={tableLinkStyles}
              onClick={() => {
                if (!dispatch) return;
                dispatch(brandsActions.setBrandToDelete(cell.row.original));
              }}
            >
              Delete
            </Button>
          </Flex>
        </Flex>
      )}
      {!isActions && cell.render('Cell')}
    </td>
  );
};

const BrandTableContainer = () => {
  const dispatch = useAppDispatch();
  const brands = useAppSelector((state) => state.brands.brands);
  const hasNextPage = useAppSelector((state) => state.brands.hasNextPage);
  const [isRefreshing, setRefreshing] = useState(false);
  const executeSearch = useAppSelector((state) => state.brands.executeSearch);

  const filters = useAppSelector((state) => state.brandsFilters);
  const filterArgs = useMemo(() => {
    const search =
      filters.searchQuery && filters.searchQuery.trim().length >= 3
        ? filters.searchQuery.trim()
        : '';
    const isUuid = validateUuid(search);

    return {
      filters: {
        name:
          !isUuid && search
            ? {
                op: FiltersStringOps.Ilike,
                value: [`%${search}%`],
              }
            : undefined,
        id:
          isUuid && search
            ? {
                op: FiltersStringOps.Equal,
                value: [search],
              }
            : undefined,
      },
      sortOrder: filters.selectedSortOrder,
    };
  }, [filters]);

  const pageSize = 100;
  const endCursor = useAppSelector((state) => state.brands.endCursor);
  const variables: IndexTableBrandsQueryVariables = {
    first: pageSize,
    after: endCursor,
    ...filterArgs,
  };

  const [result, executeQuery] = useIndexTableBrandsQuery({
    variables,
    requestPolicy: 'network-only',
  });

  const { fetching, error, data } = result;

  useEffect(() => {
    dispatch(ErrorActions.resetErrors());
    if (error) {
      dispatch(ErrorActions.setGraphqlErrors(error));
    }
  }, [error]);

  useEffect(() => {
    if (executeSearch > 0 && !fetching) {
      executeQuery();
    }
  }, [executeSearch]);

  const columns = useMemo(() => brandTableColumns, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable<ItemBrandColumns>(
    {
      columns,
      data: brands,
      manualSortBy: true,
      autoResetSortBy: true,
    },
    useSortBy,
  );

  useEffect(() => {
    if (fetching || !data?.itemBrands) {
      return;
    }

    const { edges, pageInfo } = data.itemBrands;

    const nonNullItemBrandEdges = edges.filter(
      (edge) => edge !== null,
    ) as ItemBrandEdge[];

    const brands = nonNullItemBrandEdges.map((edge) => edge.node);

    if (isRefreshing) {
      dispatch(brandsActions.setBrands(brands));
      setRefreshing(false);
    } else {
      dispatch(brandsActions.appendBrands(brands));
    }
    dispatch(brandsActions.setHasNextPage(pageInfo.hasNextPage));
  }, [data?.itemBrands?.pageInfo.endCursor, fetching]);

  useEffect(() => {
    if (data) {
      setRefreshing(true);
      dispatch(brandsActions.resetEndCursor());
    }
  }, [variables.filters, variables.sortOrder]);

  useEffect(() => {
    if (sortBy) {
      const id = sortBy.map((sort) => sort.id);
      const desc = sortBy.map((sort) => sort.desc || undefined);

      const sortDesc = !!desc[0];
      const sortField = id[0];

      if (!sortField) {
        dispatch(brandFilterActions.resetSortOrder());
      } else if (sortDesc) {
        dispatch(brandFilterActions.setSortOrderDesc(sortField));
      } else if (!sortDesc) {
        dispatch(brandFilterActions.setSortOrderAsc(sortField));
      }
    }
  }, [sortBy]);

  if (error) {
    return <b>There was an error fetching</b>;
  }

  const textCenter: ThemeUICSSObject = { textAlign: 'center' };

  return (
    <InfiniteScroll
      dataLength={brands.length}
      next={() => {
        if (data?.itemBrands) {
          if (data.itemBrands.pageInfo.endCursor) {
            dispatch(
              brandsActions.setEndCursor(data.itemBrands.pageInfo.endCursor),
            );
          }

          dispatch(
            brandsActions.setHasNextPage(data.itemBrands.pageInfo.hasNextPage),
          );
        }
      }}
      hasMore={hasNextPage}
      loader={<p sx={textCenter}>Loading...</p>}
      endMessage={
        <p sx={textCenter}>
          <b>Reached end of brands</b>
        </p>
      }
    >
      <Box sx={{ ...indexTableWrapper, marginTop: '0' }}>
        {fetching ? <Spinner sx={spinnerStyle} /> : null}
        <table {...getTableProps()} sx={tableStyles}>
          <thead sx={{ ...headerStyles, height: '40px' }}>
            {headerGroups.map((headerGroup, idx: number) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={column.id}
                    scope="col"
                    sx={headerCellStyles}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? (column.toggleSortBy, (<img src={ChevronUp} />))
                          : (column.toggleSortBy, (<img src={ChevronDown} />))
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const cellStyle = Object.assign(cellStyles, {
                opacity: fetching ? 0.2 : 1,
                paddingY: 3,
                paddingX: 2,
              });
              return (
                <tr {...row.getRowProps()} key={row.id} sx={rowStyles}>
                  {row.cells.map((cell) =>
                    cellRenderer(cell, cellStyle, dispatch),
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </InfiniteScroll>
  );
};

export default BrandTableContainer;
