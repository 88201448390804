import { Box, ThemeUICSSObject } from 'theme-ui';
import { APP_ENV, isDevelopment } from '@/utils/environment';

// Used to alter the position of the layout components that are affected by
// this banner being included in non production environments
export const TOP_OFFSET = isDevelopment ? 25 : 0;

const bannerCss: ThemeUICSSObject = {
  backgroundColor: '#FFD100',
  padding: '10px 0',
  color: 'black',
  textAlign: 'center',
  position: 'fixed',
  width: '100%',
  top: '0',
  zIndex: '999',
  textTransform: 'uppercase',
};

const DevelopmentEnvironmentBanner = () => {
  return isDevelopment ? <Box sx={bannerCss}>{APP_ENV} Environment</Box> : null;
};

export default DevelopmentEnvironmentBanner;
