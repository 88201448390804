import Modal from '@/components/Modal';
import { Box, Heading, Paragraph, ThemeUICSSObject } from 'theme-ui';
import { Button } from '@odekoteam/doppio';

const headerCSS: ThemeUICSSObject = {
  marginTop: '0',
  fontSize: '4',
  fontWeight: 'bold',
  marginBottom: '6',
};

const UnsavedChangesModal = ({
  visible,
  toggle,
  handleClose,
}: {
  visible: boolean;
  toggle: () => void;
  handleClose: () => void;
}) => {
  return (
    <Modal visible={visible}>
      <Heading as="h3" sx={headerCSS}>
        Unsaved Changes
      </Heading>
      <Paragraph sx={{ fontWeight: 'semibold' }}>
        You have unsaved changes. Are you sure you want to leave without saving
        them?
      </Paragraph>
      <Box sx={{ paddingLeft: '15', paddingTop: '9' }}>
        <Button variant="ghost" onPress={() => handleClose()}>
          Leave and clear
        </Button>
        <Button onPress={toggle}>Stay and review</Button>
      </Box>
    </Modal>
  );
};

export default UnsavedChangesModal;
