import { Global } from '@emotion/react';

/**
 * A separate module describing any global styles in detail.
 */
const GlobalStyles = () => {
  return (
    <Global
      styles={{
        /**
         * The * targets every element.
         * We're intentionally setting everything to inherit its parents box-sizing val.
         * The initial value, set on the html tag via theme.root, is border-box.
         * This is to make working with the box model more intuitive.
         * For more see: https://css-tricks.com/box-sizing/
         */
        '*, *:before, *:after': { boxSizing: 'inherit' },
        /**
         * body and #root are given height of 100% just to make sure they take up 100%
         * of the space of the parent element, in this case the html tag.
         * The styles of the html tag are handled in theme.ts, under styles.root.
         *
         * We're using this instead of viewport styles (i.e. vh) because vh targets
         * the viewport whereas using this approach allows components to use their
         * __parents__ dimensions. This gives us greater flexibility since elements
         * can respond to each others' dimnesions instead of just to the viewports.
         */
        body: {
          height: '100%',
          width: '100%',
        },
        '#root': {
          height: '100%',
          width: '100%',
        },
      }}
    />
  );
};

export default GlobalStyles;
