import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ToastState = {
  isVisible: Boolean;
};

export enum ToastAlertType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type ToastContent = {
  alertType: ToastAlertType;
  header: string;
  content: string;
};

const initialState: ToastState & ToastContent = {
  isVisible: false,
  alertType: ToastAlertType.SUCCESS,
  header: '',
  content: '',
};

const ToastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    hideToast: (state) => {
      state.isVisible = false;
      state.alertType = ToastAlertType.SUCCESS;
      state.header = '';
      state.content = '';
    },
    showToast: (
      state,
      { payload: { alertType, header, content } }: PayloadAction<ToastContent>,
    ) => {
      state.isVisible = true;
      state.alertType = alertType;
      state.header = header;
      state.content = content;
    },
  },
});

const { reducer, actions } = ToastSlice;

export const { hideToast, showToast } = actions;

export default reducer;
