import { gql } from 'urql';

gql`
  query getItemAuditLogExtras {
    categories {
      id
      name
    }
    procurementVendors {
      id
      name
    }
    units {
      id
      name
    }
    vendors {
      id
      name
    }
    warehouses {
      id
      name
    }
  }
`;

gql`
  query getReadOnlyItemExtras {
    warehouses {
      id
      name
    }
  }
`;

export const GET_UPSERT_ITEM_EXTRAS = gql`
  query getUpsertItemExtras {
    warehouses {
      id
      name
    }
    categories(filter: TOP_LEVEL) {
      id
      name
      position
      subcategories {
        id
        name
        position
      }
    }
    procurementVendors {
      id
      name
    }
    vendors {
      id
      name
      warehouse {
        name
      }
    }
    units {
      id
      name
      conversionRate
      baseUnit {
        id
        name
        conversionRate
        unitType
      }
      unitType
    }
  }
`;

export const GET_ITEM_FOR_EDIT = gql`
  query getItemForEdit($itemId: ID!, $warehouseIds: [ID!]) {
    viewer {
      availableItemById(id: $itemId) {
        __typename
        id
        name
        brandName
        brand {
          id
          name
        }
        description
        catalogStatus
        imgUrl
        itemSize
        size {
          quantity
          unit {
            id
            name
          }
        }
        updatedAt
        vendorCode
        dimensionalFactor
        eachHeightInches
        eachLengthInches
        eachWeightLbs
        eachWidthInches
        caseHeightInches
        caseLengthInches
        caseWeightLbs
        caseWidthInches
        refrigerationState
        transitMethod
        category {
          id
          name
        }
        subcategory {
          id
          name
        }
        standingOrderBehavior
        tags {
          badgeUrl
          displayAs
          id
          name
          parentTag {
            badgeUrl
            displayAs
            id
            name
          }
        }
        ... on InventoryItem {
          manufacturedType
          perishability
          upcCodes
          locationShelfLifeDays
          shelfLifeDays
          inventoryCheckBehavior
          isSerialized
          stockUnit {
            id
            name
            unitType
            baseUnit {
              name
              id
            }
          }
          purchaseUnit {
            id
            name
            unitType
            baseUnit {
              name
            }
          }
          warehouseAssociation(warehouseIds: $warehouseIds) {
            procurementVendor {
              id
            }
            salePrice {
              cents
              currency
            }
            warehouse {
              id
            }
            sample {
              id
              name
            }
            hasSample
          }
          sampleUnit {
            name
            id
          }
          sampleUpcCodes
        }
        ... on NonInventoryItem {
          minItemsPerOrder
          purchaseMultiple
          warehouseAssociation(warehouseIds: $warehouseIds) {
            vendor {
              id
            }
            salePrice {
              cents
              currency
            }
            warehouse {
              id
            }
            sample {
              id
              name
            }
            hasSample
          }
          sampleUnit {
            name
            id
          }
        }
        purchasePrice {
          cents
          currency
        }
        saleUnit {
          id
          unitType
          name
          conversionRate
        }
      }
    }
  }
`;

gql`
  query getSignedUploadImageByKey($key: String!) {
    signedUploadImageByKey(key: $key) {
      url
    }
  }
`;

gql`
  query getSignedUploadImageUrl($extension: String!) {
    signedUploadImageUrl(extension: $extension) {
      url
      key
    }
  }
`;

gql`
  query getCategoriesForWarehouse($warehouseId: ID!) {
    warehouse(warehouseId: $warehouseId) {
      categories {
        parentCategory {
          id
        }
        id
        name
        position
        subcategories {
          id
          name
          position
        }
      }
    }
  }
`;

export const GET_WAREHOUSES = gql`
  query getWarehouses {
    warehouses {
      id
      name
      brandedPaperLeadTime
    }
  }
`;

gql`
  query findItemReadOnly(
    $itemId: ID!
    $warehouseIds: [ID!]
    $withDiscardedAtItemTags: Boolean
  ) {
    viewer {
      availableItemById(id: $itemId) {
        __typename
        id
        name
        description
        catalogStatus
        createdAt
        createdBy
        imgUrl
        updatedAt
        vendorCode
        brandName
        brand {
          id
          name
        }
        category {
          name
        }
        subcategory {
          name
        }
        standingOrderBehavior
        refrigerationState
        transitMethod
        dimensionalFactor
        eachHeightInches
        eachLengthInches
        eachWeightLbs
        eachWidthInches
        caseHeightInches
        caseLengthInches
        caseWeightLbs
        caseWidthInches
        itemSize
        size {
          label
        }
        tags(withDiscardedAt: $withDiscardedAtItemTags) {
          badgeUrl
          displayAs
          id
          name
          parentTag {
            badgeUrl
            displayAs
            id
            name
            position
          }
          pivot {
            itemId
            discardedAt
          }
        }
        ... on InventoryItem {
          perishability
          upcCodes
          locationShelfLifeDays
          shelfLifeDays
          inventoryCheckBehavior
          isSerialized
          stockUnit {
            name
            unitType
            baseUnit {
              name
            }
          }
          purchaseUnit {
            name
            unitType
            baseUnit {
              name
            }
          }
          warehouseAssociation(warehouseIds: $warehouseIds) {
            procurementVendor {
              id
              name
            }
            salePrice {
              cents
              currency
            }
            warehouse {
              id
              name
            }
            sample {
              id
              name
            }
            hasSample
          }
          sampleUnit {
            name
            id
          }
          sampleUpcCodes
          erpRecordUrl
        }
        ... on NonInventoryItem {
          minItemsPerOrder
          purchaseMultiple
          warehouseAssociation(warehouseIds: $warehouseIds) {
            vendor {
              id
              name
              warehouse {
                name
              }
            }
            salePrice {
              cents
              currency
            }
            warehouse {
              id
              name
            }
            sample {
              id
              name
            }
            hasSample
          }
          sampleUnit {
            name
            id
          }
          erpRecordUrl
        }
        purchasePrice {
          cents
          currency
        }
        saleUnit {
          unitType
          name
          conversionRate
        }
      }
    }
    productInventory(productId: $itemId) {
      availableCount
      warehouse {
        id
        name
      }
    }
  }
`;

gql`
  query IndexTableItems(
    $first: Int
    $after: String
    $filters: ItemsFiltersInput
    $sortOrder: [ItemsSortOrder]
    $searchQuery: String
  ) {
    viewer {
      availableItems(
        first: $first
        after: $after
        filters: $filters
        sortOrder: $sortOrder
        searchQuery: $searchQuery
      ) {
        edges {
          node {
            name
            id
            name
            brandName
            brand {
              id
              name
            }
            catalogStatus
            category {
              name
              id
              position
            }
            imgUrl
            saleUnit {
              name
            }
            updatedAt
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

gql`
  query IndexTableBrands(
    $first: Int
    $after: String
    $filters: ItemBrandsFiltersInput
    $sortOrder: [ItemBrandsSortOrder]
  ) {
    itemBrands(
      first: $first
      after: $after
      filters: $filters
      sortOrder: $sortOrder
    ) {
      edges {
        node {
          name
          id
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const FIND_BRAND_BY_NAME = gql`
  query FindBrandByName(
    $first: Int
    $after: String
    $filters: ItemBrandsFiltersInput
    $sortOrder: [ItemBrandsSortOrder]
  ) {
    itemBrands(
      first: $first
      after: $after
      filters: $filters
      sortOrder: $sortOrder
    ) {
      edges {
        node {
          name
          id
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_ITEM_TAGS = gql`
  query ItemTags(
    $first: Int
    $after: String
    $filters: ItemTagFiltersInput
    $sortOrder: [ItemTagSortOrder]
    $searchQuery: String
  ) {
    viewer {
      itemTags(
        first: $first
        after: $after
        filters: $filters
        sortOrder: $sortOrder
        searchQuery: $searchQuery
      ) {
        edges {
          node {
            id
            name
            badgeUrl
            position
            displayAs
            parentTag {
              id
              name
              position
            }
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

gql`
  query AuditLogsForObject(
    $after: String
    $before: String
    $endDate: ISO8601DateTime
    $first: Int
    $last: Int
    $objects: [AuditLogObjectInput]
    $sortOrder: [AuditLogSortOrder]
    $startDate: ISO8601DateTime
  ) {
    viewer {
      auditLogs(
        after: $after
        before: $before
        endDate: $endDate
        first: $first
        last: $last
        objects: $objects
        sortOrder: $sortOrder
        startDate: $startDate
      ) {
        edges {
          cursor
          node {
            causedBy
            changeType
            date
            field
            newValue
            objectId
            oldValue
            objectType
            user {
              email
              id
              name
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
    }
  }
`;

export const GET_LANDED_COST_FOR_ITEM = gql`
  query getLandedCostForItem($itemId: ID!, $warehouseIds: [ID!]) {
    viewer {
      availableItemById(id: $itemId) {
        name
        purchasePrice {
          cents
          currency
        }
        ... on InventoryItem {
          warehouseAssociation(warehouseIds: $warehouseIds) {
            landedCost {
              cents
              currency
            }
          }
        }
        ... on NonInventoryItem {
          warehouseAssociation(warehouseIds: $warehouseIds) {
            landedCost {
              cents
              currency
            }
          }
        }
      }
    }
  }
`;

export const GET_ITEM_NAME_BY_UUID = gql`
  query getItemNameByUUID($itemId: ID!) {
    viewer {
      availableItemById(id: $itemId) {
        name
        catalogStatus
      }
    }
  }
`;

export const GET_LOCATION_NAME_BY_UUID = gql`
  query Location($odekoUuid: ID) {
    location(odekoUuid: $odekoUuid) {
      businessName
    }
  }
`;

export const QUERY_LOCATION_ITEMS_BY_ITEM_ID = gql`
  query LocationItemsByItemId($locationItemsByItemId: ID!) {
    locationItemsByItemId(id: $locationItemsByItemId) {
      locationId
    }
  }
`;

export const QUERY_LOCATIONS_SEARCH = gql`
  query locationsSearch($first: Int!, $name: String!) {
    viewer {
      locations(first: $first, name: $name) {
        nodes {
          id
          businessName
          warehouse {
            id
            name
          }
        }
      }
    }
  }
`;
