import { ThemeUICSSObject } from 'theme-ui';
import Search from '../../assets/images/Search.svg';

export const ModalHeaderStyle: ThemeUICSSObject = {
  marginTop: '0',
  fontSize: '4',
  fontWeight: 'bold',
  marginBottom: '6',
};

export const ModalErrorBannerStyle: ThemeUICSSObject = {
  bg: 'warningHighlight',
  display: 'flex',
  width: '100%',
  height: 'auto',
  padding: '6px 17px 6px 12px',
  alignItems: 'flex-start',
  marginBottom: '16px',
  gap: '5px',
  justifyContent: 'flex-start',
};

export const ModalFooterActions: ThemeUICSSObject = {
  justifyContent: 'flex-end',
  marginTop: '30px',
  gap: 4,
};

export const tableLinkStyles: ThemeUICSSObject = {
  display: 'inline-block',
  padding: 0,
  width: '100%',
  textDecoration: 'none',
  color: 'secondaryDefault',
  height: '40px',
  fontWeight: 'bold',
  border: 0,
  background: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
    border: 0,
    fontWeight: 'extrabold',
  },
};

export const searchBarContainerStyle: ThemeUICSSObject = {
  marginY: '22px',
};

export const searchBarStyle: ThemeUICSSObject = {
  padding: '10px 10px 10px 40px',
  textalign: 'center',
  fontSize: '16px',
  background: `url(${Search}) no-repeat`,
  backgroundPosition: '10px 8px',
  borderRadius: 0,
  border: 'none',
};
