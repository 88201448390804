import React from 'react';
import { Notifier } from '@airbrake/browser';
import { notify } from '@/services/ErrorReporter';

// This is a component class and not a hook because it relies on the lifecycle method componentDidCatch,
// for which a hook is not yet available. (though the React team plans on implementing it soon:
// https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes)
class ErrorBoundary extends React.Component {
  protected airbrake: Notifier | null = null;

  public componentDidCatch(error: any, info: any) {
    // Send error to Airbrake
    notify(error, info);
  }

  public render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
