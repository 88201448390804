import { Box, Input } from 'theme-ui';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import React from 'react';
import { brandFilterActions } from '@/state/brands/filters';
import {
  searchBarContainerStyle,
  searchBarStyle,
} from '@/components/Brand/Styles';

const BrandSearchBar = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.brandsFilters);
  const searchQuery = filters.searchQuery ?? '';

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value === '') {
      dispatch(brandFilterActions.resetSearchQuery());
    } else {
      dispatch(brandFilterActions.setSearchQuery(event.currentTarget.value));
    }
  };

  return (
    <Box sx={searchBarContainerStyle}>
      <Input
        value={searchQuery}
        onChange={handleChange}
        placeholder="Search by brand name or id"
        sx={searchBarStyle}
      />
    </Box>
  );
};

export default BrandSearchBar;
