import { Alert, Box, Close, Flex, Text, ThemeUICSSObject } from 'theme-ui';
import SuccessAlert from '@/assets/images/SuccessAlert.svg';
import ErrorAlert from '@/assets/images/ErrorAlert.svg';
import { useAppSelector, useAppDispatch } from '@/hooks/store';
import { ToastAlertType, hideToast } from '@/state/toast';

const containerStyles: ThemeUICSSObject = {
  position: 'fixed',
  top: '33px',
  right: '10px',
  backgroundColor: 'neutralLight',
  border: '1px solid #E6E2DA',
  boxShadow: '0px 0px 10px rgba(196, 196, 196, 0.5)',
  borderRadius: '8px',
  zIndex: 999,
};

const headerCSS: ThemeUICSSObject = {
  color: 'neutralDark',
  fontSize: '1',
  lineHeight: '0',
  fontWeight: 'bold',
};

const contentCSS: ThemeUICSSObject = {
  color: 'secondaryText',
  fontSize: '1',
  lineHeight: '1',
  fontWeight: 'semibold',
};

const closeButtonCSS: ThemeUICSSObject = {
  fontSize: '2',
  fontWeight: 'bold',
  bg: 'transparent',
  color: 'neutralDark',
  cursor: 'pointer',
};

const contentColorMap: Record<ToastAlertType, string> = {
  [ToastAlertType.SUCCESS]: 'secondaryText',
  [ToastAlertType.ERROR]: 'secondaryLabel',
};

const alertImageMap: Record<ToastAlertType, string> = {
  [ToastAlertType.SUCCESS]: SuccessAlert,
  [ToastAlertType.ERROR]: ErrorAlert,
};

const ToastNotification = () => {
  const { isVisible, alertType, header, content } = useAppSelector(
    (state) => state.toast,
  );
  const dispatch = useAppDispatch();
  contentCSS.color = contentColorMap[alertType];
  return isVisible ? (
    <Alert sx={containerStyles}>
      <Flex>
        <img src={alertImageMap[alertType]} sx={{ marginBottom: '4' }} />
        <Box sx={{ padding: '10px' }}>
          <Box>
            <Text sx={headerCSS}>{header}</Text>
          </Box>
          <Box sx={{ paddingTop: '4px' }}>
            <Text sx={contentCSS}>{content}</Text>
          </Box>
        </Box>
        <Close sx={closeButtonCSS} onClick={() => dispatch(hideToast())} />
      </Flex>
    </Alert>
  ) : null;
};

export default ToastNotification;
