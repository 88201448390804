import AdminUserLoginChecker from '@/components/AdminUserLoginChecker';
import DevelopmentEnvironmentBanner from '@/components/DevelopmentEnvironmentBanner';
import ToastNotification from '@/components/ToastNotification';
import client from '@/graphql/client';
import { useAppSelector } from '@/hooks/store';
import { lazy, Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider as UrqlProvider } from 'urql';
import ScrollToTop from './components/ScrollToTop';
import GlobalStyles from './GlobalStyles';

const Index = lazy(() => import('@/pages/Index'));
const CreateItem = lazy(() => import('@/pages/CreateItem'));
const Edit = lazy(() => import('@/pages/EditItem'));
const Discontinue = lazy(() => import('@/pages/Discontinue'));
const ReadOnlyItem = lazy(() => import('@/pages/ReadOnlyItem'));
const CreateBaseBrandedItem = lazy(
  () => import('@/pages/CreateBaseBrandedItem'),
);
const EditBaseBrandedItem = lazy(() => import('@/pages/EditBaseBrandedItem'));
const ReadOnlyBaseBrandedItem = lazy(
  () => import('@/pages/ReadOnlyBaseBrandedItem'),
);

import { withLDProvider } from 'launchdarkly-react-client-sdk';
import BaseBrandedItemIndex from './pages/BaseBrandedItemIndex';
import LocationItemManager, {
  LocationItemManagerUrl,
} from './pages/LocationItemManager';
import BrandedItemLeadTime from './pages/BrandedItemLeadTime';
import BrandManagerIndex from './pages/BrandManagerIndex';

/**
 * Initialize our GraphQL Client.
 * Set the env var GRAPHQL_ENDPOINT to use an endpoint other than the backend's
 * (in the item-management-service repo)
 *
 * NOTE: devtoolsExchange should be the first exchange according to the docs
 * https://github.com/FormidableLabs/urql-devtools-exchange#usage
 */

const App = () => {
  const urlqConfig = client(useAppSelector((state) => state.auth.token)!);
  return (
    <>
      <GlobalStyles />
      <AdminUserLoginChecker>
        <UrqlProvider value={urlqConfig}>
          <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
              <ScrollToTop />
              <DevelopmentEnvironmentBanner />
              <ToastNotification />
              <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/discontinue/:id" element={<Discontinue />} />
                <Route path="/edit/:id" element={<Edit />} />
                <Route path="/item/:id" element={<ReadOnlyItem />} />
                <Route path="/new/:type" element={<CreateItem />} />
                <Route
                  path={LocationItemManagerUrl}
                  element={<LocationItemManager />}
                />
                <Route
                  path={`${LocationItemManagerUrl}/:id`}
                  element={<LocationItemManager />}
                />
                <Route
                  path="/base_branded_items"
                  element={<BaseBrandedItemIndex />}
                />
                <Route
                  path="/base_branded_items/lead_times"
                  element={<BrandedItemLeadTime />}
                />
                <Route
                  path="/base_branded_item/new/:type"
                  element={<CreateBaseBrandedItem />}
                />
                <Route
                  path="/base_branded_item/edit/:id"
                  element={<EditBaseBrandedItem />}
                />
                <Route
                  path="/base_branded_item/item/:id"
                  element={<ReadOnlyBaseBrandedItem />}
                />
                <Route path="/brands" element={<BrandManagerIndex />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </UrqlProvider>
      </AdminUserLoginChecker>
    </>
  );
};

export default !window.env.LAUNCH_DARKLY_SDK_KEY
  ? hot(App)
  : withLDProvider({
      clientSideID: window.env.LAUNCH_DARKLY_SDK_KEY,
      options: {
        bootstrap: 'localStorage',
        streaming: true,
      },
    })(hot(App));
