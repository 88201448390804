import { Button } from '@odekoteam/doppio';

type HeaderCTAProps = {
  text: string;
  form: string;
  disabled: boolean;
};

const HeaderCTA = ({ text = '', form, disabled = false }: HeaderCTAProps) => (
  <Button
    isDisabled={disabled}
    onPress={() => document.forms.namedItem(form)?.requestSubmit()}
  >
    {text}
  </Button>
);

export default HeaderCTA;
