import React, { useEffect, useState } from 'react';
import client from '@/graphql/client';
import { useAppSelector } from '@/hooks/store';
import { GET_WAREHOUSES } from '@/graphql/requests/queries';
import { Warehouse } from '@/graphql/types/generated';
import {
  LeadTimeForm,
  formId,
} from '@/components/UpsertBrandedpaperLeadTime/form';
import { Box, Heading, Spinner } from 'theme-ui';
import { spinnerStyle } from '@/components/BaseBrandedItemIndex/ItemTableStyles';
import { UPDATE_WAREHOUSE_LEAD_TIME } from '@/graphql/requests/mutations';
import { ErrorActions } from '@/state/errors';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ErrorBanner from '@/components/ErrorBanner';
import TwoColStickyHeaderLayout from '@/layouts/two-col-sticky-header';
import HeaderCTA from '@/components/forms/HeaderCTA';

const Header = () => (
  <Heading variant="headingSm" padding="1rem 0 2rem">
    This will adjust the lead time added to products after the proof is
    approved.
  </Heading>
);

const Content = ({
  warehouses,
  onSubmit,
}: {
  warehouses: Warehouse[];
  onSubmit: (values: any) => Promise<void>;
}) => (
  <Box>
    <Header />
    <ErrorBanner />
    <LeadTimeForm
      warehouses={warehouses}
      onSubmit={onSubmit}
      minDeliveryTime={1}
    />
  </Box>
);

const mapWarehouseToQuery = (wh: Warehouse) => ({
  warehouseUuid: wh.id,
  // input type=number returns a string
  brandedPaperLeadTime: parseInt(
    wh.brandedPaperLeadTime as unknown as string,
    10,
  ),
});

const BrandedItemLeadTime = () => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [showSpinner, setShowSpinner] = useState<Boolean>(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = useAppSelector((state) => state.auth.token);
  const graphql = client(authToken as string);

  const onSubmit = async (values: any) => {
    const input = {
      warehouses: values.warehouses.map(mapWarehouseToQuery),
    };

    const { error } = await graphql
      .mutation(UPDATE_WAREHOUSE_LEAD_TIME, { input })
      .toPromise();

    dispatch(ErrorActions.resetErrors());

    if (error) {
      dispatch(ErrorActions.setGraphqlErrors(error));
      return;
    }

    return navigate(`/base_branded_items`);
  };

  useEffect(() => {
    (async () => {
      const warehousesResponse = await graphql
        .query(GET_WAREHOUSES)
        .toPromise();
      setWarehouses(warehousesResponse.data.warehouses);
      setShowSpinner(false);
    })();
  }, []);

  return (
    <TwoColStickyHeaderLayout
      sidebar={<span />}
      content={
        showSpinner ? (
          <Spinner sx={spinnerStyle} />
        ) : (
          <Content warehouses={warehouses} onSubmit={onSubmit} />
        )
      }
      cta={
        <HeaderCTA text="Update Lead times" form={formId} disabled={false} />
      }
      headerText={showSpinner ? '' : 'Branded Items Lead time'}
      handleClose={() => navigate(`/base_branded_items`)}
    />
  );
};

export default BrandedItemLeadTime;
