import { ThemeUICSSObject } from 'theme-ui';

export const DownloadHeadingStyle: ThemeUICSSObject = {
  fontSize: '14px',
  fontWeight: '600',
  fontFamily: 'GreycliffCF-Bold',
};

export const DropdownContainerStyle: ThemeUICSSObject = {
  width: '200px',
  color: 'neutralDark',
};

export const HorizontalRowStyle: ThemeUICSSObject = {
  backgroundColor: 'secondaryDisabled',
  width: '100%',
  height: '1px',
  margin: '20px 0 15px 0',
};

export const UnorderedListStyle: ThemeUICSSObject = {
  listStyle: 'none',
  padding: 0,
  marginBottom: 0,
};

export const ListStyle: ThemeUICSSObject = {
  margin: '15px 0',
};

export const TemplateLinkStyle: ThemeUICSSObject = {
  color: 'neutralDark',
  fontSize: '14px',
  fontWeight: '500',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '7px 5px',
  borderRadius: '5px',
};

export const ButtonDropdownContainerStyle: ThemeUICSSObject = {
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'secondaryDisabled',
  background: 'none',
  color: 'secondaryDefault',
};

export const ButtonUploadStyle: ThemeUICSSObject = {
  background: 'none',
  color: 'secondaryDefault',
  fontWeight: 'bold',
  fontFamily: 'Roobert-bold',
};

export const ButtonDropdownStyle: ThemeUICSSObject = {
  background: 'none',
  color: 'secondaryDefault',
};

export const VerticalDividerStyle: ThemeUICSSObject = {
  backgroundColor: 'secondaryDisabled',
  width: '1px',
  height: '75%',
};

export const DropDownContainerStyle: ThemeUICSSObject = {
  alignItems: 'center',
};
