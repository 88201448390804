import { ItemBrand } from '@/graphql/types/generated';
import { Cell, Column } from 'react-table';

const NameCell = ({ value }: Cell<ItemBrand>) => {
  return (
    <div sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div sx={{ fontWeight: 'semibold', color: 'neutralDark' }}>{value}</div>
    </div>
  );
};

export type ItemBrandColumns = {
  id: string;
  name: string;
  actions?: string;
};

export const brandTableColumns: Column<ItemBrandColumns>[] = [
  {
    Header: 'Brand Name',
    accessor: 'name',
    Cell: NameCell,
  },
  {
    Header: '',
    accessor: 'actions',
  },
];
