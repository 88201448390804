import { Autocomplete } from '@odekoteam/doppio';
import { useEffect, useState } from 'react';
import {
  useIndexTableItemsQuery,
  IndexTableItemsQueryVariables,
} from '@/graphql/types/generated';
import { AutocompleteOption } from '@doppio/elements/build/Autocomplete/AutocompleteList';
import { MarginYAuto } from '@/components/UpsertBaseBrandedItem/utils/tableStyles';
import { validate as validateUuid } from 'uuid';
import { SelectTextOnFocus } from '@/components/LocationItemManager/utils';

type Props = {
  selectedItemId?: string;
  placeholderText?: string;
  onSelect: (itemId: string) => void;
  itemsQueryVars?: IndexTableItemsQueryVariables;
  selectTextOnFocus?: boolean;
  initialTextValue?: string;
};

type SelectedItem = {
  id: string;
  name: string;
};

const getItems = (
  variables: IndexTableItemsQueryVariables,
  skip?: boolean,
): AutocompleteOption[] => {
  const [result] = useIndexTableItemsQuery({
    variables,
    requestPolicy: 'network-only',
    pause: !variables.searchQuery || variables.searchQuery.length < 3 || skip,
  });

  if (!variables.searchQuery || skip || result.fetching) {
    return [];
  }

  const { data } = result;
  const isUuid = validateUuid(variables.searchQuery);
  return (
    data?.viewer?.availableItems.edges?.map<AutocompleteOption>((x) => ({
      label: isUuid ? `${x?.node.name} (${x?.node.id})` : `${x?.node.name}`,
      value: x?.node.id,
    })) || []
  );
};

const CatalogItemAutocomplete = ({
  selectedItemId,
  onSelect,
  itemsQueryVars,
  selectTextOnFocus = false,
  initialTextValue = '',
  placeholderText = `Search by item name, id`,
}: Props) => {
  const [searchQuery, setSearchQuery] = useState(initialTextValue);
  const [selectedItem, setSelectedItem] = useState<SelectedItem>();

  // set initial item in form if there is one
  const skipInitial = !selectedItemId || !!selectedItem;
  const variables: IndexTableItemsQueryVariables = {
    first: 25,
    after: '',
    filters: {},
    ...itemsQueryVars,
  };
  variables.searchQuery = searchQuery;

  const initialItem = getItems(variables, skipInitial)[0];
  useEffect(() => {
    if (initialItem !== undefined && initialItem.value !== selectedItem?.id) {
      setSelectedItem({
        id: initialItem.value as string,
        name: initialItem.label as string,
      });
      onSelect(initialItem.value as string);
    }
  }, [initialItem]);

  const selectTextOnFocusHandler = selectTextOnFocus
    ? SelectTextOnFocus
    : undefined;

  return (
    <div sx={MarginYAuto}>
      <Autocomplete
        onFocus={selectTextOnFocusHandler}
        onBlur={() => {
          if (!selectedItem) {
            setSearchQuery('');
            return;
          }

          if (searchQuery.length === 0) {
            // field has been cleared, reset selectedItem
            setSelectedItem(undefined);
            return;
          }
        }}
        value={searchQuery}
        onScroll={function noRefCheck() {}}
        onSelect={(option) => {
          setSelectedItem({
            id: option.value as string,
            name: option.label as string,
          });
          onSelect(option.value as string);
        }}
        options={getItems(variables)}
        placeholder={placeholderText}
        onChangeText={(text) => {
          setSearchQuery(text);
        }}
      />
    </div>
  );
};

export default CatalogItemAutocomplete;
