import {
  emptyCategory,
  emptyPricing,
  emptyUnit,
} from '@/components/forms/types/emptyForm';
import {
  WarehousesRow,
  toInventoryWarehouseAssociationInput,
  toNonInventoryWarehouseAssociationInput,
} from '@/components/supplyItems/types/WarehouseRows';
import {
  CatalogStatus,
  InventoryCheckBehavior,
  InventoryItem,
  InventoryItemInput,
  ItemTag,
  ManufacturedType,
  Money,
  NonInventoryItem,
  NonInventoryItemInput,
  StandingOrderBehavior,
  SupplyItem,
} from '@/graphql/types/generated';
import { WarehouseAssociation } from '@/types';
import { priceInput, priceOutput } from '@/utils/PricingIO';

export const defaultDimensionalFactor = 194;

export const createBaseItem = (): SupplyItem & {
  warehouseAssociation: WarehouseAssociation[];
} => ({
  brandName: '',
  brand: undefined,
  catalogStatus: CatalogStatus.NotInCatalog,
  category: emptyCategory,
  createdAt: new Date(),
  createdBy: '',
  description: undefined,
  id: '',
  imgUrl: '',
  itemSize: '',
  size: {
    quantity: 1,
    unit: emptyUnit,
    label: '',
  },
  name: '',
  purchasePrice: emptyPricing,
  saleUnit: emptyUnit,
  standingOrderBehavior: StandingOrderBehavior.NotAvailable,
  subcategory: emptyCategory,
  updatedAt: new Date(),
  updatedBy: '',
  vendorCode: '',
  warehouseAssociation: [],
  dimensionalFactor: defaultDimensionalFactor,
  eachHeightInches: undefined,
  eachLengthInches: undefined,
  eachWeightLbs: undefined,
  eachWidthInches: undefined,
  caseHeightInches: undefined,
  caseLengthInches: undefined,
  caseWeightLbs: undefined,
  caseWidthInches: undefined,
  refrigerationState: undefined,
  tags: [],
});

type AbstractItemUpsertForm = NonInventoryItem | InventoryItem;

type UpsertItemFormFields = {
  uiWarehouseRows: WarehousesRow[];
  isInventoryType: boolean;
};

export type UpsertNonInventoryItemForm = NonInventoryItem &
  UpsertItemFormFields;

export type UpsertInventoryItemForm = InventoryItem & UpsertItemFormFields;

export type UpsertItemForm =
  | UpsertNonInventoryItemForm
  | UpsertInventoryItemForm;

/**
 * Creates an empty form for creation of new {@link SupplyItem}
 * @param isInventoryItem true to create an {@link InventoryItem}, false for {@link NonInventoryItem}
 */
const createEmptyForm = (isInventoryItem: boolean): AbstractItemUpsertForm => {
  const baseItem: SupplyItem = createBaseItem();
  if (isInventoryItem) {
    return {
      ...baseItem,
      __typename: 'InventoryItem',
      inventoryCheckBehavior: InventoryCheckBehavior.Check,
      locationShelfLifeDays: null,
      perishability: undefined,
      sampleUpcCodes: [],
      shelfLifeDays: 24,
      stockUnit: emptyUnit,
      purchaseUnit: emptyUnit,
      upcCodes: [],
      excludedFromPickingLabels: false,
      isSerialized: false,
    };
  }
  return {
    ...baseItem,
    __typename: 'NonInventoryItem',
    minItemsPerOrder: 1,
    purchaseMultiple: 1,
    excludedFromPickingLabels: false,
  };
};

/**
 * Creates a form with the item we are editing {@link SupplyItem}
 * @param item the {@link UpsertItemForm}
 */
export const createEditForm = (item: UpsertItemForm): UpsertItemForm => {
  const pricing: Money = priceOutput(item?.purchasePrice);
  return {
    ...item,
    purchasePrice: pricing,
  };
};

/**
 * Converts a {@link NonInventoryItem} to a {@link NonInventoryItemInput}
 * @param item the {@link NonInventoryItem}
 * @param uiWarehouseRows an array of the {@link WarehousesRow} from the form
 */
export const toNonInventoryItemInput = (
  item: NonInventoryItem,
  uiWarehouseRows: WarehousesRow[],
  disablePriceEditing: boolean,
): NonInventoryItemInput => {
  const associationInputs = uiWarehouseRows
    .filter((v) => v.enabled || v.vendorId)
    .map((v) =>
      toNonInventoryWarehouseAssociationInput(v, disablePriceEditing),
    );

  return {
    brandName: item.brand?.name ?? item.brandName,
    brand: item.brand?.id,
    catalogStatus: item.catalogStatus,
    category: item.category.id,
    description: item.description,
    imgUrl: item.imgUrl,
    itemSize: item.itemSize,
    size:
      item.size !== undefined
        ? {
            quantity: Number(item.size!.quantity),
            unit: item.size!.unit.id!,
          }
        : undefined,
    minItemsPerOrder: Number(item.minItemsPerOrder),
    name: item.name,
    purchaseMultiple: Number(item.purchaseMultiple),
    purchasePrice: priceInput(item.purchasePrice),
    saleUnit: item.saleUnit.id,
    sampleUnit: item.sampleUnit?.id,
    standingOrderBehavior: item.standingOrderBehavior,
    subcategory: item.subcategory?.id,
    vendorCode: item.vendorCode,
    warehouseAssociation: associationInputs,
    dimensionalFactor:
      item.dimensionalFactor !== null ? Number(item.dimensionalFactor) : null,
    eachHeightInches:
      item.eachHeightInches !== null ? Number(item.eachHeightInches) : null,
    eachLengthInches:
      item.eachLengthInches !== null ? Number(item.eachLengthInches) : null,
    eachWeightLbs:
      item.eachWeightLbs !== null ? Number(item.eachWeightLbs) : null,
    eachWidthInches:
      item.eachWidthInches !== null ? Number(item.eachWidthInches) : null,
    caseHeightInches:
      item.caseHeightInches !== null ? Number(item.caseHeightInches) : null,
    caseLengthInches:
      item.caseLengthInches !== null ? Number(item.caseLengthInches) : null,
    caseWeightLbs:
      item.caseWeightLbs !== null ? Number(item.caseWeightLbs) : null,
    caseWidthInches:
      item.caseWidthInches !== null ? Number(item.caseWidthInches) : null,
    refrigerationState: item.refrigerationState,
    transitMethod: item.transitMethod,
    tags: item.tags ? item.tags.map(({ id }: ItemTag) => id) : null,
  };
};

/**
 * Converts a {@link  NonInventoryItem} to a {@link NonInventoryItemInput}
 * @param item the {@link NonInventoryItem}
 * @param uiWarehouseRows an array of the {@link WarehousesRow} from the form
 */
export const toInventoryItemInput = (
  item: InventoryItem,
  uiWarehouseRows: WarehousesRow[],
  disablePriceEditing: boolean,
): InventoryItemInput => {
  const isCustomBrandedItem: boolean =
    item.manufacturedType === ManufacturedType.CustomBrandedItem;
  const associationInputs = uiWarehouseRows
    .filter(
      (v) =>
        v.enabled ||
        v.vendorId ||
        (isCustomBrandedItem && v.salePrice !== undefined),
    )
    .map((v) => toInventoryWarehouseAssociationInput(v, disablePriceEditing));

  return {
    brandName: item.brand?.name ?? item.brandName,
    brand: item.brand?.id,
    catalogStatus: item.catalogStatus,
    category: item.category.id,
    description: item.description,
    imgUrl: item.imgUrl,
    itemSize: item.itemSize,
    isSerialized: item.isSerialized,
    size:
      item.size !== undefined
        ? {
            quantity: Number(item.size!.quantity),
            unit: item.size!.unit.id!,
          }
        : undefined,
    inventoryCheckBehavior: item.inventoryCheckBehavior,
    locationShelfLifeDays:
      item.locationShelfLifeDays !== null
        ? Number(item.locationShelfLifeDays)
        : null,
    name: item.name,
    perishability: item.perishability,
    purchasePrice: priceInput(item.purchasePrice),
    purchaseUnit: item.purchaseUnit.id,
    refrigerationState: item.refrigerationState,
    saleUnit: item.saleUnit.id,
    sampleUnit: item.sampleUnit?.id,
    sampleUpcCodes: item.sampleUpcCodes,
    shelfLifeDays:
      item.shelfLifeDays !== null ? Number(item.shelfLifeDays) : null,
    standingOrderBehavior: item.standingOrderBehavior,
    stockUnit: item.stockUnit?.id,
    subcategory: item.subcategory?.id,
    upcCodes: item.upcCodes,
    vendorCode: item.vendorCode,
    warehouseAssociation: associationInputs,
    dimensionalFactor:
      item.dimensionalFactor !== null ? Number(item.dimensionalFactor) : null,
    eachHeightInches:
      item.eachHeightInches !== null ? Number(item.eachHeightInches) : null,
    eachLengthInches:
      item.eachLengthInches !== null ? Number(item.eachLengthInches) : null,
    eachWeightLbs:
      item.eachWeightLbs !== null ? Number(item.eachWeightLbs) : null,
    eachWidthInches:
      item.eachWidthInches !== null ? Number(item.eachWidthInches) : null,
    caseHeightInches:
      item.caseHeightInches !== null ? Number(item.caseHeightInches) : null,
    caseLengthInches:
      item.caseLengthInches !== null ? Number(item.caseLengthInches) : null,
    caseWeightLbs:
      item.caseWeightLbs !== null ? Number(item.caseWeightLbs) : null,
    caseWidthInches:
      item.caseWidthInches !== null ? Number(item.caseWidthInches) : null,
    transitMethod: item.transitMethod,
    tags: item.tags ? item.tags.map(({ id }: ItemTag) => id) : null,
  };
};

/**
 * Dummy text until product/design
 */
export const loremIpsumDolorAcet = 'Lorem ipsum dolor acet';
export const nameToolTip = 'The name of the item.';
export const brandToolTip = 'The name of the brand that produces the item.';
export const categoryToolTip =
  'The section the item should be listed in for easy navigation.';
export const subcategoryToolTip =
  'The subsection the item should be listed in (ie. Milk - Dairy)';
export const descriptionToolTip =
  "The product description as listed on the vendor's website or catalog.";
export const imageUrlToolTip =
  'A photo of the item to be displayed in the Catalog. Images should be in JPG or PNG format and a maximum of 5MB.';
export const vendorOrderingCodeToolTip =
  'How the procurement vendor denotes the item.';
export const minItemsPerOrderToolTip =
  'The minimum number of items required to purchase.';
export const purchasePriceToolTip =
  'The cost of each individual item from the vendor.';
export const upcCodeToolTip = 'The unique ID of the item.';
export const saleUnitToolTip = 'The number of units required to be purchased.';
export const sampleUnitToolTip = 'The number of units provided for sampling.';
export const sampleUpcCodeToolTip = 'The unique ID of the sample.';
export const stockUnitToolTip =
  'The number of units used to stock this item in inventory.';
export const perishableToolTip = 'The estimated shelf life of the item.';
export const catalogStatusToolTip =
  'Toggling this enables customers to add this item to their Catalog, provided the given warehouse has the item.';
export const vendorCodeToolTip = "Vendor's Ordering Code.";
export const itemSizeToolTip =
  'Measure of a unit of the item, e.g. 12 oz, 1 gallon. Can be in weight, volume, or if none of those, then just "each"';
export const purchaseUnitToolTip =
  'How many units are purchased at a time for storage.';
export const locationShelfLifeDaysToolTip =
  'The shelf life of the item when we receive it into our warehouse';
export const shelfLifeDaysToolTip =
  'The number of days that should be remaining until an item expires when we deliver it.';
export const perishabilityToolTip = 'The estimated shelf life of the item.';
export const samplesDescription =
  'Some products are able to be sent as samples to our network. Please indicate the sample unit below.';
export const sampleSalesUnitToolTip = 'Sample Unit';
export const catalogDescriptionToolTip =
  'Additional information needed to publish an item in the catalog.';
export const enableinCatalogToolTip =
  'Toggling this enables customers to add this item to their Catalog, provided the given warehouse has the item.';
export const standingOrderToolTip =
  'Whether or not the item will be available for standing orders.';
export const skipOOSOrders =
  'Permission to skip Out-Of-Stock checks for this item prior to ordering, with the expectation it will always be in stock.';
export const refrigerationStateToolTip =
  'Whether or not the item requires refrigeration during storage and transport.';
export const minQuantityToOrderToolTip =
  'The minimum number of items required to purchase.';
export const purchaseMultipleToolTip =
  'The number of items recieved per purchase order.';
export const ordersDescriptionToolTip =
  'The way in which orders for this item should be classified.';
export const inventoryItemDescription =
  'Inventory items are currently carried in our warehouse(s).';
export const locationItemsDescription = `Add this item to a location's catalog by selecting which locations to add below.`;
export const procurementInfoDescription =
  'Information necessary to publish the item in our inventory.';
export const NonInventoryItemDescription =
  'Non-Inventory Item - An item supplied by other vendors.';
export const transitMethodToolTip =
  'Represents how inventory arrives at an Odeko facility, e.g., pick-up, drop-off. This impacts landed cost of owned inventory';
export const FormId = 'ItemForm';
export const modalHeader = 'Unsaved Changes';
export const modalContent =
  'You have unsaved changes. Are you sure you want to leave without saving them?';
export const modalRedirectButtonText = 'Leave and clear';

export const modalCloseButtonText = 'Stay and review';

export const eachLengthToolTip =
  'Length of a singular inventory or non-inventory item in inches';
export const eachWidthToolTip =
  'Width of a singular inventory or non-inventory item in inches';
export const eachHeightToolTip =
  'Height of a singular inventory or non-inventory item in inches';
export const eachWeightToolTip =
  'Weight of a singular inventory or non-inventory item in inches';
export const caseLengthToolTip =
  'Length of a singular inventory or non-inventory case in inches';
export const caseWidthToolTip =
  'Width of a singular inventory or non-inventory case in inches';
export const caseHeightToolTip =
  'Height of a singular inventory or non-inventory case in inches';
export const caseWeightToolTip =
  'Weight of a singular inventory or non-inventory case in pounds';
export const eachVolumeToolTip =
  'L x W x H of a singular inventory or non-inventory item in cubic inches';
export const caseVolumeToolTip =
  'L x W x H of a case of inventory or non-inventory items in cubic inches';

export const dimensionalFactorToolTip =
  'Represents the volume of a package allowed per unit of weight as assigned by FedEx. Currently, this factor is contractually set at 194.';

export const eachDimensionalWeightToolTip =
  'The amount of space an item occupies in relation to its actual weight';
export const caseDimensionalWeightToolTip =
  'The amount of space a case of items occupies in relation to its actual weight';

export const itemTagsDescriptionToolTip =
  'Tags are used to better classify items and appear better on search';

export const isSerializedToolTip =
  'Mark the item as a “Serialized Item” in NetSuite (i.e., an item that has a serial number). This cannot be modified after the item is published.';

export default createEmptyForm;
