import React from 'react';
import { filterActions } from '@/state/Filters';
import { useAppDispatch } from '@/hooks/store';
import { useGetBaseBrandedItemCategoriesQuery } from '@/graphql/types/generated';
import { NativeSelect, NativeSelectProps } from '@odekoteam/doppio';

type CategoryFilterSelectProps = {
  input: { value?: string; name?: string };
  disabled?: boolean;
};

const CategoryFilterSelect = ({
  input: { value },
  disabled,
}: CategoryFilterSelectProps) => {
  const dispatch = useAppDispatch();

  const [categoryResponse] = useGetBaseBrandedItemCategoriesQuery();

  const categoryList = categoryResponse.data?.categories || [];

  const categoryData = categoryList.filter(
    (category) => category.parentCategory === null,
  );

  const items: NativeSelectProps['items'] = [
    { label: 'All Categories', value: '' },
    ...categoryData.map((o) => ({ value: o.id, label: o.name })),
  ];

  const handleChange: NativeSelectProps['onValueChange'] = (value) => {
    if (value === '') {
      dispatch(filterActions.resetCategory());
    } else {
      dispatch(filterActions.setCategory(value as string));
    }
    dispatch(filterActions.resetSubcategory());
  };
  return (
    <NativeSelect
      value={value}
      isDisabled={disabled}
      items={items}
      onValueChange={handleChange}
    />
  );
};

export default CategoryFilterSelect;
