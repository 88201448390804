import { ThemeUICSSObject } from 'theme-ui';

export const tableStyles: ThemeUICSSObject = {
  borderCollapse: 'collapse',
  minWidth: '100%',
  py: 1,
  overflowX: 'scroll',
  fontSize: 1,
  marginTop: 4,
};
export const headerStyles: ThemeUICSSObject = {
  bg: 'backgroundWeak',
};
export const headerCellStyles: ThemeUICSSObject = {
  paddingX: 3,
  paddingY: 2,
  textAlign: 'left',
  color: 'secondaryText',
  fontWeight: 'regular', // NOTE: <th> has a bold font weight by default
};
export const rowStyles: ThemeUICSSObject = {
  borderBottom: '1px solid',
  borderColor: 'secondaryHighlight',
  color: 'secondaryText',
  ':hover': {
    backgroundColor: 'backgroundWeak',
  },
};
export const cellStyles: ThemeUICSSObject = {
  paddingX: 0,
  alignItems: 'center',
  paddingY: 1,
};

export const linkInheritStyles: ThemeUICSSObject = {
  color: 'inherit',
  border: '0',
  textDecoration: 'none',
  display: 'flex',
  minHeight: '60px',
  alignItems: 'center',
  paddingX: 3,
};

export const indexTableWrapper: ThemeUICSSObject = {
  position: 'relative',
};

export const spinnerStyle: ThemeUICSSObject = {
  position: 'fixed',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
};
