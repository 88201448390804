import { Label, Flex, ThemeUICSSObject } from 'theme-ui';
import Tooltip from '../Tooltip';
import { ReactNode } from 'react';

type LabelTooltipGroupProps = {
  label: string;
  name: string;
  tooltipText: string;
  fieldError?: boolean;
  children?: ReactNode;
};
const LabelTooltipGroup = ({
  label,
  name,
  tooltipText,
  fieldError,
  children,
}: LabelTooltipGroupProps) => {
  const groupStyles: ThemeUICSSObject = {
    alignItems: 'center',
  };
  const labelStyles: ThemeUICSSObject = {
    color: fieldError ? 'errorDefault' : '',
    margin: '0 2px',
    variant: 'labelMd',
    marginBottom: '8px',
    fontWeight: 'semibold',
  };

  return tooltipText ? (
    <Flex sx={groupStyles}>
      <Label sx={labelStyles}>{label}</Label>
      <Tooltip description={tooltipText} name={name} />
      {children}
    </Flex>
  ) : null;
};

export default LabelTooltipGroup;
