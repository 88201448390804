import { ThemeUICSSObject } from 'theme-ui';

export const modalPopCSS: ThemeUICSSObject = {
  zIndex: '999',
  width: '100%',
  height: '100%',
  position: 'fixed',
  left: '0',
  top: '0',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
};

export const modalContentPopCSS: ThemeUICSSObject = {
  backgroundColor: 'neutralLight',
  borderRadius: '3',
  zIndex: '999',
  margin: 'auto',
  maxWidth: '420px',
  padding: '32px',
  width: '100%',
  height: 'auto',
};

export const modalOverlayCSS: ThemeUICSSObject = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  zIndex: '99',
  backgroundColor: 'neutralDark',
  opacity: '0.5',
};
