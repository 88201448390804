import { InputHTMLAttributes, ReactNode } from 'react';
import { Input, ThemeUICSSObject } from 'theme-ui';
import LabelTooltipGroup from './LabelTooltipGroup';

export type InputTypeProp = 'text' | 'number';

export type InputGroupProps = {
  input: InputHTMLAttributes<HTMLInputElement>;
  label?: string;
  tooltipText?: string;
  fieldError?: boolean;
  inputStyle?: ThemeUICSSObject;
  children?: ReactNode;
  labelTooltipChildren?: ReactNode;
  disabled?: boolean;
  type?: InputTypeProp;
  extraProps?: object;
};

export const mapFieldErrorToStyle = (
  sx: ThemeUICSSObject = {},
  fieldError?: boolean,
) => (fieldError ? { ...sx, borderColor: 'errorDefault' } : sx);

const InputGroup = ({
  input,
  label = '',
  tooltipText = '',
  fieldError,
  children,
  inputStyle,
  labelTooltipChildren,
  disabled,
  type = 'text',
  extraProps = {},
}: InputGroupProps) => {
  const { name = '' } = input;
  return (
    <>
      <LabelTooltipGroup
        tooltipText={tooltipText}
        name={name}
        label={label}
        fieldError={fieldError}
      >
        {labelTooltipChildren}
      </LabelTooltipGroup>
      {children || (
        <Input
          sx={mapFieldErrorToStyle(inputStyle, fieldError)}
          {...input}
          type={type}
          disabled={disabled}
          {...extraProps}
        />
      )}
    </>
  );
};

export default InputGroup;
