import React from 'react';
import { filterActions } from '@/state/Filters';
import { useAppDispatch } from '@/hooks/store';
import { PrintingMethod } from '@/graphql/types/generated';
import { NativeSelect, NativeSelectProps } from '@odekoteam/doppio';

type PrintMethodFilterSelectProps = {
  input: { value?: string; name?: string };
  disabled?: boolean;
};

const ItemTypeFilterSelect = ({
  input: { value },
  disabled,
}: PrintMethodFilterSelectProps) => {
  const dispatch = useAppDispatch();

  const handleChange: NativeSelectProps['onValueChange'] = (value) => {
    if (value === '') {
      dispatch(filterActions.resetItemType());
    } else {
      dispatch(filterActions.setPrintMethod(value as PrintingMethod));
    }
  };

  const items: NativeSelectProps['items'] = [
    { label: 'All Types', value: '' },
    { label: 'In-House', value: PrintingMethod.InHouse },
    { label: 'External', value: PrintingMethod.External },
  ];

  return (
    <NativeSelect
      value={value}
      isDisabled={disabled}
      items={items}
      onValueChange={handleChange}
    />
  );
};

export default ItemTypeFilterSelect;
